<section class="bg-half-170 d-table w-100 padding_200" id="home"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading mt-4">
                    <h1 class="heading mb-3"><span class="text-primary fw-bold">Zero</span> Balance Savings Account</h1>
                    <p class="para-desc text-muted">Now open zero balance savings accounts for your customers and earn commission on every new account!</p>
                    <div class="mt-4 pt-2">
                        <a href="#lead_form" class="btn btn-primary m-1">Join Now</a>
                        
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/images/Image-1.png" alt="" class="img-fluid">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

<div class="pb-70"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Retailer and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>

            <section class="section bg-light">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title me-lg-5">
                                <h4 class="title mb-4">JIFFY Advantage Savings Account.</h4>
                                <p class="text-muted">Jiffy Advantage Savings Account is a 100% Digital - Zero Balance savings account that comes with a host of features and benefits. You can open this account for your customer within a few minutes using just his Aadhaar and PAN number. With instant activation of account, customer can enjoy instant fund transfers, utility bill payments, quick recharges, UPI payments and much more.</p>
                                <a href="#lead_form" class="btn btn-outline-primary">Start Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-md-6 order-1 order-md-2">
                            <img src="../../../../assets/images/Image-2.png" class="img-fluid" alt="">
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
    
                
            </section>



            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/application.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                                    <div class="col-lg-8 col-md-7 col-12">
                                        <div class="section-title">
                                            <h4 class="title title-dark text-white mb-4">Happy Pay</h4>
                                            <p class="para-desc para-dark mb-0 text-light">Become a part of the fastest growing network of Independent Business Owners and become financially independent.</p>
                                        </div>
                                        <div class="row" id="counter">
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-graduation-cap title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="500">500</span>+</h5>
                                                    <h6 class="counter-head title-dark text-light">Districts Covered</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-book-open title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="1">1</span>Lac+</h5>
                                                    <h6 class="counter-head title-dark text-light">Retail Touch Points</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-user title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="10">10</span>Lacs+</h5>
                                                    <h6 class="counter-head title-dark text-light">Monthly Unique Customers</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-english-to-chinese title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="15">15</span>Cr+</h5>
                                                    <h6 class="counter-head title-dark text-light">Daily Transaction Volume</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>