



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">CA & Legal Services</h1>
                    <p class="para-desc text-muted">
                        Our core team consists of skilled and proficient qualified Advocates, along with a diligent team of other professionals, all under one roof. They provide solution to all the individual, business person, corporate body and others to get better help for the issues faced by them in their everyday life.
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/loans/main.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section style="margin-top: 70px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> CA &amp; Legal Services </span></h4>
                   <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/consumer-complaint.webp" alt="image" width="210" height="200">

                    <h3>Consumer Complaint</h3>
                    <p style="font-size:15px">When a customer is unsatisfied by the service of any service provider or seller, when defects are detected in concerned products or service or when a customer is cheated by a seller, then a complaint can be filed from the consumer’s side.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/2-Company-Registration.webp" alt="image" width="210" height="200">

                    <h3>Company Registration</h3>
                    <p style="font-size:15px">It includes all the forms of company registration, compliances, PAN and TAN, GST, change of Registered Address. We provide quick and inexpensive company registration through a 100% online procedure.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/3-Online-Accounting-Services.webp" alt="image" width="210" height="200">

                    <h3>Online Accounting Services</h3>
                    <p style="font-size:15px"> We, at Happy Pay, provide an expert, dependable and proficient virtual accounting services to small businesses, CPA’s, CFO’s, non-profit organizations, entrepreneurs, and individuals across the globe.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/4FSSAI-Registration-copy.webp" alt="image" width="210" height="200">

                    <h3>FSSAI Registration</h3>
                    <p style="font-size:15px">Get FSSAI certificate & license no. to utilize on your products and website as well.
                    </p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/5-Import-Export-License.webp"alt="image" width="210" height="200">

                    <h3>Import Export License</h3>
                    <p style="font-size:15px">Register your import export business under Directorate General of Foreign Trade, Govt. of India.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/6Trademark-Registration.webp" alt="image" width="210" height="200">

                    <h3>Trademark Registration</h3>
                    <p style="font-size:15px">Get your Trademark registration from anywhere in India and protect your Brand Name/Logo/Slogan.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/7-GST-Services.webp" alt="image" width="210" height="200">

                    <h3>GST Services</h3>
                    <p style="font-size:15px">Outsource your GST compliance to Online Legal India. India’s fastest growing online corporate services.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/8-CONTRACT.webp" alt="image" width="210" height="200">

                    <h3>Legal Services</h3>
                    <p style="font-size:15px">We provide the legal services to the weaker section of the society so that they get the opportunity</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/10-FSSAI-Food-License-Renewal.webp" alt="image" width="210" height="200">

                    <h3>FSSAI Food License Renewal</h3>
                    <p style="font-size:15px">FSSAI license is mandatory to be renewed before the expiry validity time, otherwise, apply for a new license.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/11-ITR-Filing-Online.webp" alt="image" width="210" height="200">

                    <h3>ITR Filing Online</h3>
                    <p style="font-size:15px">An Income tax return (ITR) is a form used to file information about your income</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/legal/digital-signature.jpg" alt="image" width="210" height="200">

                    <h3>Digital Signature Certificate</h3>
                    <p style="font-size:15px">Instant apply paperless aadhaar eKYC digital signature certificate through Happy Pay</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section>


<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 0px 0px 55px 0px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>