



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Bill Payments & Recharges</h1>
                    <p class="para-desc text-muted">Earn more by doing DTH/ Mobile recharges, Utility bill payments, for your customers easily and quickly of more than 150 companies
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="max-width: 80%; height: 100%;" src="../../../../assets/images/Recharge & Pay Bills.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<section class="padding_200 " id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/mobile-recharge.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Mobile Recharge</h1>
                    <p class="para-desc text-muted">With the Happy Pay merchant app in your hands, mobile recharge could not have become easier. It not only is simple, fast, and convenient but equally hassle-free too.Happy Pay merchant app enables you to complete every transaction request of your customers. We support almost every mobile operator and covers options from both ends of the spectrum namely Prepaid and Postpaid
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

      <!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>






<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">DTH Recharge</h1>
                    <p class="para-desc text-muted">Now, don’t let anything come in between your customer and their entertainment. With the Happy Pay merchant app, choose from a variety of operators like Airtel DTH, Dish TV, Videocon D2h, and Tata sky to name a few, and select the best package for your customer depending on your customer’s needs. So choose Happy Pay for doing DTH recharge anytime and anywhere
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/dth-recharge.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>




<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/utility-recharge.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Utility Recharge</h1>
                    <p class="para-desc text-muted">Gone are the days when anyone used to stand in a long queue, waiting for hours just for filling their monthly bills namely Electricity, Water, and Gas. With the Happy Pay merchant app in your mobile, you can ease your customer’s life and save their time by giving them the option of filling their monthly bill/ dues through your smartphone, Happy Pay merchant app, and most importantly through you !
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

   
        </div><!--end row-->
    </div><!--end container--> 
</section>
<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 0px 0px 55px 0px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>