<section id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Banking Services</h1>
                    <p class="para-desc text-muted">
                        Convert your shop into Mini Bank through AePS, Micro-ATM & Money Transfer. Provide cash withdrawal, domestic and Indo-Nepal money transfer to earn huge commissions
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="max-width: 90%; height: 100%;" src="../../../../assets/images/services/banking/main.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section class="padding_200 " id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/banking/aeps.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Aadhar ATM</h1>
                    <p class="para-desc text-muted">With the Happy Pay merchant app in your hands, mobile recharge could not have become easier. It not only is simple, fast, and convenient but equally hassle-free too.Happy Pay merchant app enables you to complete every transaction request of your customers. We support almost every mobile operator and covers options from both ends of the spectrum namely Prepaid and Postpaid
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

      <!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Micro ATM</h1>
                    <p class="para-desc text-muted">Are you tired of long queues, technical glitches, and cash unavailability at your nearest bank outlet. Well then, don’t worry about it anymore, because Happy Pay brings you Micro ATM feature which can magically convert your regular shop into an ATM outlet. With us, you would be able to provide basic ATM services like cash withdrawal and balance inquiry to your customers


                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/banking/micro-atm.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
   
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/banking/money-transfer-1.svg" class="max-width-80 rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Money Transfer (DMT, Indo-Nepal)</h1>
                    <p class="para-desc text-muted">Happy Pay we understand your concerns and try to come up with solutions for addressing those issues. We realized that sending money to your friends and family was still a difficult task for many people and for solving that problem, we bring you the Happy Pay money transfer feature. This can be used to send money anywhere across India and Nepal. It is highly safe, secure, and reliable


                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

   
        </div><!--end row-->
    </div><!--end container--> 
</section>

<div id="section_footer">
    <section class="d-table w-100 footer_new_section" id="home section_footer" style="padding: 0px 0px 55px 0px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    

            </div>