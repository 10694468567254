<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/images/logo.png" alt="logo"></a>
                    <p>Happy Pay is a leader in providing technical and management training for Students.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/aboutus">About</a></li>
                        <li><a routerLink="/courses-1">Courses</a></li>
                        <li><a routerLink="/events">Events</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Student Success</a></li>
                        <li><a routerLink="/">Scholarships</a></li>
                        <li><a routerLink="/">For Business</a></li>
                        <li><a routerLink="/">Go Premium</a></li>
                        <li><a routerLink="/">Team Plans</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Happy Pay</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/refund-policy">Refund Policy</a></li>
                        <li><a target="_blank" href="https://surveyheart.com/form/64809e55bc5cf3533fece595">Request to
                                Delete Account</a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Revenue Ward No 26, no 26-30-19, Yerukuvanipalem,
                            Ramalayam Street, VISAKHAPATNAM, Paravada Industrial
                            Area, Anakapalli, Andhra Pradesh, 531019</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:8886317755"> +91 8886317755</a></li>
                        <li><i class='bx bx-envelope'></i><a
                                href="mailto:Info.buddica@gmail.com">Info.buddica@gmail.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+91 8886317755</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="footer-bottom-area">
            <div class="row align-items-center">


                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>

                <div>
                    <ul class="list-unstyled d-flex justify-content-center align-items-center"
                        routerLink="/privacy-policy">
                        <li>Copyright@2023 Buddica Global Solutions Private Limited</li>
                    </ul>
                </div>
            </div>
        </div> -->
        <div class="footer-bottom-area">
            <div class="row align-items-center">

                <div class="col-lg-4 col-md-6 d-flex justify-content-start">
                    <ul class="list-unstyled">
                        <li>Copyright@2023 Buddica Global Solutions Private Limited</li>
                    </ul>
                </div>

                <div class="col-lg-8 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>

            </div>
        </div>

    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>