<div class="advisor-area bg-f9f9f9 pt-100 mb-5" style="background-color:#fff;">
    <div class="container">
        <div class="section-title">
    
            <h2>Corporate Partners</h2>
        </div>
        <div class="advisor-slides">
            <owl-carousel-o [options]="advisorSlides">
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/airtel.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/axis.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Bharat-Bill-Pay-Logo.png" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/cashfree.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/digio.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Dish-TV-Logo.png" >
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/fino.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/icici.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/indusind.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Mahindra-624x210.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/NPCI-Logo.png" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/yesbank.jpg" >
                                </div>
                            </div>       
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box mt-3 pt-1">
                        <div class="row align-items-center mt-5">
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/NSDL-624x210.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/paytm.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/razorpay.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/RBL-Bank.png" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/sbi.jpg" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/SBM-Smart-Banking-unit-1.png" >
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Tata-Sky-Logo.png" >
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Uti-pan-services-624x210.jpg" >
                                </div>
                            </div>                     
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/Videocon-Logo.png" >
                                </div>
                            </div>                     
                            <div class="col-lg-2 col-md-12">
                                <div class="advisor-image">
                                    <img src="../../../../../assets/images/partners/yes-fintech.png" >
                                </div>
                            </div>                     
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>