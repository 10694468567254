<section class="padding_200 d-table w-100 overflow-hidden" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3"><span class="text-primary fw-bold">AEPS</span> – Aadhaar Enabled Payment Services</h1>
                    <p class="para-desc text-muted"><span class="text-primary fw-bold">Happy Pay</span> has integrated with all the available banks to enable cash withdrawal and other AEPS services for all its partners.</p>
                    
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-app-image position-relative">
                    <div class=" position-relative">
                        <img src="../../../../assets/images/AEPS-Top-Image.png" class=" mx-auto d-block " alt="" width="100%">
                    </div>
                    <div class="app-images d-none d-md-block">
                        
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<div class="pb-70"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Retailer and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>



            <section class="padding_head_below overflow-hidden">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                
                                <h2 class="title mb-4">AEPS Services in Happy Pay</h2>
                                <p class="text-muted para-desc mx-auto mb-0">Customers can visit their nearest <span class="text-primary fw-bold">Happy Pay AEPS</span> agent and do all these transactions using only their Aadhaar Number and Biometric Authentication.</p>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
    
                    <div class="row">
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-light rounded shadow d-inline-block">
                                        <img src="../../../../assets/images/art-and-design.svg" class="avatar avatar-small" alt="">
                                    </div>
                                    <div class="mt-4">
                                        <h3><a href="javascript:void(0)" class="text-dark">Balance Inquiry: </a></h3>
                                        <p class="text-muted mt-3 mb-0">AEPS Agent can check the balance of the customer simply in less than a minute by using Happy Pay portal or mobile application. We have the best mobile App to do AEPS.</p>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center bg-primary bg-gradient rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-light rounded shadow d-inline-block">
                                        <img src="../../../../assets/images/smartphone.svg" class="avatar avatar-small" alt="">
                                    </div>
                                    <div class="mt-4">
                                        <h3><a href="javascript:void(0)" class="text-white title-dark">Aadhaar ATM: </a></h3>
                                        <p class="text-white-50 mt-3 mb-0">Instead of searching for an ATM, AEPS Agents of Happy Pay can convert their own store into an ATM using this service. Customers can withdraw money using their Aadhaar Number and finger print.</p>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-light rounded shadow d-inline-block">
                                        <img src="../../../../assets/images//clock.svg" class="avatar avatar-small" alt="">
                                    </div>
                                    <div class="mt-4">
                                        <h3><a href="javascript:void(0)" class="text-dark">Mini statement:</a></h3>
                                        <p class="text-muted mt-3 mb-0">Checking the mini statement of a customer has never been simpler. AEPS Agent will also get a commission every time he checks the mini statement of the customer via AEPS.</p>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
    
                
            </section>



            <section class="section bg-light padding_head_below">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title me-lg-5">
                                <h2 class="title mb-4">What is AEPS? </h2>
                                <p class="text-muted">Aadhaar Enabled Payment Service or AEPS, is an Aadhaar based payment solution which empowers bank users to carry out financial transactions (without any debit card/credit card/ cheque book) using only their Aadhaar card, registered with their bank account, and biometric authentication. AEPS agents and distributors can provide basic banking services to their customers such as cash withdrawal, balance inquiry and obtaining a mini statement. It is an initiative by NPCI (National Payments Corporation of India) to support unbanked and underbanked sections of India.</p>
    
                                <p class="text-muted">At Happy Pay, we not only add the AEPS Agent or AEPS Distributor to our network, but also take steps to guide them in earning more. Anyone can join our network with a simple AEPS Agent registration form that needs to be filled.</p>
                               <!-- <a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i class="uil uil-angle-right-b"></i></a>-->
                            </div>
                        </div><!--end col-->
    
                        <div class="col-md-6 order-1 order-md-2">
                            <img src="../../../../assets/images/What-is-AEPS.png" class="img-fluid" alt="">
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->         
            </section>


            <section class="">
                <div class="container mt-60 padding_class">
                   <div class="row align-items-center">
                       <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                           <div class="section-title me-lg-5">
                               <h2 class="title mb-4">How does it work?</h2>
                               <p class="text-muted">Once the AEPS Agent registration is done, your Happy Pay AEPS Agent login id will be generated. The process of AEPS is very simple.</p>
                               <ul class="list-unstyled text-muted">
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>The AEPS Agent connects and installs a biometric device to their Computer or Smart phone.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agent inputs the Customer Aadhaar number and the bank name.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agent selects the transaction type: Cash withdrawal or Balance Inquiry.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>The customer then inputs their thumb print (same as per Aadhaar) to authenticate the transaction.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>The customer’s account is debited and merchant’s Happy Pay wallet account is credited real time along with additional commission amount.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>The AEPS Agent then receives a transaction receipt and customer receives an SMS confirmation from bank.</li>
   
                               </ul>
   
                               
                           </div>
                       </div><!--end col-->
   
                       <div class="col-lg-5 col-md-6 order-1 order-md-2">
                           <img src="../../../../assets/images/How-does-it-work.png" alt="" class="img-fluid">
                       </div><!--end col-->
                   </div><!--end row-->
               </div><!--end container-->
               
               
               <div class="container mt-40 mt-60">
                   <div class="row align-items-center">
                       <div class="col-lg-6 col-md-6">
                           <img src="../../../../assets/images/top-feature-AEPS.png" class="img-fluid" alt="">
                       </div><!--end col-->
   
                       <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                           <div class="section-title ms-lg-5">
                               
                               <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i class="uil uil-angle-right-b"></i></a> -->
   
                               
                                  
                               <h2 class="title mb-4">Top Features</h2>
   
                               <ul class="list-unstyled text-muted">
   
                               <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Real Time Transaction Settlement along with commission</li>
   
                               <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Safe &amp; Secure system.</li>
   
                               <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>No need to carry a Debit or Credit card, Only the Aadhaar number and fingerprint authentication required. </li>
   
                               <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Easily withdraw money, avoid long queues at the bank or ATM.</li>
   
                               <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Quick process, doesn't take more than a minute to complete a transaction.</li>
                           </ul>
                       
   
   
                           </div>
                       </div><!--end col-->
                   </div><!--end row-->
               </div><!--end container-->
   
               <div class="container mt-100 mt-60">
                   <div class="row align-items-center">
                       <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                           <div class="section-title me-lg-5">
                               <h2 class="title mb-4">Benefits to AEPS agent</h2>
                               <!-- <p class="text-muted"></p> -->
                               <ul class="list-unstyled text-muted">
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Minimum Investment with Maximum Return.</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Only requirements to set up business, are a Smart phone or Computer (as per ease and availability) &amp; Biometric Device.</li>
   
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Earn Attractive Commission per transaction.</li>
   
   
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Quick process, takes less than a minute to complete the transaction.</li>
   
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full utilisation of cash in hand.</li>
   
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Increase in customer base due to additional service provided.</li>
   
   
                               </ul>
   
                               
                           </div>
                       </div><!--end col-->
   
                       <div class="col-lg-5 col-md-6 order-1 order-md-2">
                           <img src="../../../../assets/images/Benefits-to-AEPS-Agents.png" alt="" class="img-fluid">
                       </div><!--end col-->
                   </div><!--end row-->
               </div><!--end container-->
               
               <div class="container mt-100 mt-60">
                   <div class="row justify-content-center">
                       <div class="col-12 text-center">
                           <div class="section-title mb-4 pb-2">
                               <h2 class="title mb-4">Why <span class="text-primary fw-bold">Happy Pay?</span></h2>
                               
   
   
                           </div>
                       </div><!--end col-->
                   </div><!--end row-->
   
                   <!--
   
                   <div class="row justify-content-center">
                       <div class="col-lg-12 mt-4">
                           <div class="tiny-three-item">
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/01.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "</p>
                                           <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
                                       </div>
                                   </div>
                               </div>
                               
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/02.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "</p>
                                           <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/03.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                           <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/04.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "</p>
                                           <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/05.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used when a text is required. "</p>
                                           <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div class="tiny-slide">
                                   <div class="d-flex client-testi m-1">
                                       <img src="images/client/06.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                       <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul class="list-unstyled mb-0">
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                               <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "</p>
                                           <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div> -->
   
   
                   <div class="row">
                       <div class="col-md-4 col-12">
                               <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-airplay h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Real Time Settlement:</h4>
                                   <p class="text-muted mb-0">AEPS agents and Distributors get instant and real time settlement on every transaction.</p>
                               </div>
                           </div>
                       </div><!--end col-->
                       
                       <div class="col-md-4 col-12 mt-5 mt-sm-0">
                           <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-calendar-alt h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Business Setup:</h4>
                                   <p class="text-muted mb-0">Ease of doing the AEPS business setup with us, we will help you every step of the way.</p>
                               </div>
                           </div>
                       </div><!--end col-->
   
                                           
                       <div class="col-md-4 col-12 mt-5 mt-sm-0">
                           <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-clock h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Service Workforce:</h4>
                                   <p class="text-muted mb-0">Trained and experienced service workforce to help your AEPS business flourish.</p>
                               </div>
                           </div>
                       </div><!--end col-->
   
                       
   
                       
                       <div class="col-md-4 col-12 mt-5 mt-sm-0">
                           <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-credit-card h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Transactions:</h4>
                                   <p class="text-muted mb-0">Save time by conducting transactions through Happy Pay Web Portal or Mobile Application.</p>
                               </div>
                           </div>
                       </div><!--end col-->
   
                       <div class="col-md-4 col-12 mt-5 mt-sm-0">
                           <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-data-sharing h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Seamless Process:</h4>
                                   <p class="text-muted mb-0">Seamless process designed to reduce transaction time and effort using Happy Pay portal.</p>
                               </div>
                           </div>
                       </div><!--end col-->
   
   
                       <div class="col-md-4 col-12 mt-5 mt-sm-0">
                           <div class="features">
                               <div class="image position-relative d-inline-block">
                                   <i class="uil uil-data-sharing h1 text-primary"></i>
                               </div>
   
                               <div class="content mt-4">
                                   <h4>Zero Downtime: </h4>
                                   <p class="text-muted mb-0">Multiple banks integrated in the back end to ensure service is always up and running.</p>
                               </div>
                           </div>
                       </div><!--end col-->
   
   
   
   
                        
   
                   </div><!--end row-->
   
   
               </div><!--end container-->
   
   
   
   
   
   
               <div class="container padding_head_below">
                   <div class="row align-items-center">
                       <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                           <div class="section-title">
                               <h4 class="title mb-4">Activation Process </h4>
                               <p class="text-muted">Any new or existing business partner can do AEPS free portal registration with a simple documentation process. All you need to do is to submit your PAN Card and Aadhar Card and fill the Aadhar Enable Payment System / Aeps registration form. Earn a handsome income on every transaction!</p>
                               <p class="text-muted">Things required to start AEPS Business?</p>
                               <ul class="list-unstyled text-muted">
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Smart Phone or Computer with an active internet connection</li>
                                   <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Biometric Device</li>
                               </ul>
                               
                           </div>
                       </div><!--end col-->
   
                       <div class="col-lg-6 col-md-6 order-1 order-md-2">
                           <img src="../../../../assets/images/deal-hend.svg" class="img-fluid" alt="">
                       </div><!--end col-->
                   </div><!--end row-->
               </div>
   
   
   
   
   
   
   
               
           </section>

            <section class="section overflow-hidden padding_50">
                <div class="container pb-5 mb-md-5">
                   
                    <div class="row justify-content-center" id="counter">
                        <div class="col-12 text-center">
                            <div class="section-title">
                                <h4 class="title mb-4 padding_50">3 Steps to Become <span class="text-primary fw-bold">Happy Pay</span> Retailer</h4>
                                
                            
                                
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
    
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <div class="features text-center">
                                <div class="image position-relative d-inline-block">
                                    
                                    <img src="../../../../assets/images/step_1.png" alt="step-1" width="225">
                                    <!-- <i class="uil uil-airplay h1 text-primary"></i> -->
                                </div>
    
                                <div class="content mt-4">
                                    <h5>Download Happy Pay Android Application</h5>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-4 col-12 mt-5 mt-sm-0">
                            <div class="features text-center">
                                <div class="image position-relative d-inline-block">
                                    <img src="../../../../assets/images/step_2_.png" alt="step-1" width="225">
                                    <!-- <i class="uil uil-calendar-alt h1 text-primary"></i> -->
                                </div>
    
                                <div class="content mt-4">
                                    <h5>Signup &amp; Fill Your Personal and Business Details</h5>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-4 col-12 mt-5 mt-sm-0">
                            <div class="features text-center">
                                <div class="image position-relative d-inline-block">
                                    <img src="../../../../assets/images/step_3.png" alt="step-1" width="225">
                                  <!--  <i class="uil uil-clock h1 text-primary"></i> -->
                                </div>
    
                                <div class="content mt-4">
                                    <h5>Upload KYC Documents</h5>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
    
                <div class="container pb-5 mb-md-5 mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="saas-feature-shape-left position-relative">
                                <img src="../../../../assets/images/Retailer-2.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div class="section-title ms-lg-4">
                                <h4 class="mb-3">Happy Pay Retailer</h4>
                                <p class="para-desc text-muted">Happy Pay advanced platform enables any user to do number of different financial and utility transactions on the go. Anyone can start business with negligible investment and start earning from day one. Happy Pay now has more than 50000 retailers who are leveraging its unique technology and earning up to 50000 every month.</p>
    
    
                                <h4 class="mb-3">Who can become Happy Pay Retailer?</h4>
                                <p class="para-desc text-muted">Anyone who knows how to operate an android phone can become a Happy Pay Retailer and start his own business.</p>
    
                                <h4 class="mb-3">Investment required to become Happy Pay Retailer?</h4>
                                <p class="para-desc text-muted">This unique business opportunity is completely Free of cost. Yes, you can start this business at 0 investment</p>
    
    
                                <!-- Biznext extra 
                                
                                <div class="tns-outer" id="tns1-ow"><div class="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide <span class="current">1</span>  of 6</div><div id="tns1-mw" class="tns-ovh"><div class="tns-inner" id="tns1-iw"><div class="tiny-single-item  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" id="tns1" style="transform: translate3d(0%, 0px, 0px);">
                                    <div class="tiny-slide tns-item tns-slide-active" id="tns1-item0">
                                        <div class="client-testi">
                                            <img src="images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "</p>
                                            <h6 class="text-primary">- Thomas Israel</h6>
                                        </div>
                                    </div>
        
                                    <div class="tiny-slide tns-item" id="tns1-item1" aria-hidden="true" tabindex="-1">
                                        <div class="client-testi">
                                            <img src="images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                            <h6 class="text-primary">- Carl Oliver</h6>
                                        </div>
                                    </div>
        
                                    <div class="tiny-slide tns-item" id="tns1-item2" aria-hidden="true" tabindex="-1">
                                        <div class="client-testi">
                                            <img src="images/client/03.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "</p>
                                            <h6 class="text-primary">- Barbara McIntosh</h6>
                                        </div>
                                    </div>
        
                                    <div class="tiny-slide tns-item" id="tns1-item3" aria-hidden="true" tabindex="-1">
                                        <div class="client-testi">
                                            <img src="images/client/04.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "</p>
                                            <h6 class="text-primary">- Jill Webb</h6>
                                        </div>
                                    </div>
        
                                    <div class="tiny-slide tns-item" id="tns1-item4" aria-hidden="true" tabindex="-1">
                                        <div class="client-testi">
                                            <img src="images/client/05.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used when a text is required. "</p>
                                            <h6 class="text-primary">- Dean Tolle</h6>
                                        </div>
                                    </div>
        
                                    <div class="tiny-slide tns-item" id="tns1-item5" aria-hidden="true" tabindex="-1">
                                        <div class="client-testi">
                                            <img src="images/client/06.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                                            <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "</p>
                                            <h6 class="text-primary">- Christa Smith</h6>
                                        </div>
                                    </div>
                                </div></div></div> -->
    
    
    
                                </div>
                            </div>
                        </div><!-- end col-->
                    </div><!--end row-->
                <!--end container-->
    
                
    
    
    
                <!-- <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div class="section-title me-lg-4">
                                <h1 class="title mb-3">Why Choose Us ?</h1>
                                <p class="para-desc text-muted">Biznext offers its retailers numerous advantages over its competitors. Join today and fulfill your dreams of becoming a business owner.</p>
                            
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex align-items-center pt-4">
                                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield fea icon-m-md text-primary"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg></h2>
                                            <div class="ms-3">
                                                <h5>One Wallet</h5>
                                                <p class="text-muted mb-0">Biznext has all the services under one common wallet, no need to maintain separate wallet for different services.</p>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="col-12">
                                        <div class="d-flex align-items-center pt-4">
                                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu fea icon-m-md text-primary"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg></h2>
                                            <div class="ms-3">
                                                <h5>Real Time Income</h5>
                                                <p class="text-muted mb-0">You get commission as soon as the transaction is done. No need to wait for the month end to get commission</p>
                                            </div>
                                        </div>
                                    </div>
    
    
    
                                    <div class="col-12">
                                        <div class="d-flex align-items-center pt-4">
                                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu fea icon-m-md text-primary"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg></h2>
                                            <div class="ms-3">
                                                <h5>Performance</h5>
                                                <p class="text-muted mb-0">Biznext has been developed using latest technology. It is far superior and advanced.</p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-12">
                                        <div class="d-flex align-items-center pt-4">
                                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu fea icon-m-md text-primary"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg></h2>
                                            <div class="ms-3">
                                                <h5>Fully Secured</h5>
                                                <p class="text-muted mb-0">Biznext is fully secured against all types of attacks. Your money is safe 24/7</p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-12">
                                        <div class="d-flex align-items-center pt-4">
                                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu fea icon-m-md text-primary"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg></h2>
                                            <div class="ms-3">
                                                <h5>Mini Bank</h5>
                                                <p class="text-muted mb-0">Opportunity to become digital banker of your area and provide almost all the banking services</p>
                                            </div>
                                        </div>
                                    </div>
    
    
    
    
                                    
                                    <div class="col-12 pt-4">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary">Install Now <i class="uil uil-angle-right-b"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-7 order-1 order-lg-2">
                            <div class="saas-feature-shape-right position-relative">
                                <img src="images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
                            </div>
                        </div>
                    </div>-->
                 
            </section>

            <section class="">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Why Choose Us?</h4>
                                <p class="text-muted para-desc mb-0 mx-auto"><span class="text-primary fw-bold">Happy Pay</span> offers its retailers numerous advantages over its competitors. Join today and fulfill your dreams of becoming a business owner.</p>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
    
                    <div class="row">
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-chart-line"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Hign Performance</h5>
                                    <p class="para text-muted mb-0">High speed servers for optimum performance.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-chart-line"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-crosshairs"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Fully Secured</h5>
                                    <p class="para text-muted mb-0">Extremely secured servers to ensure full safety.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-crosshairs"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-airplay"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Trusted Portal</h5>
                                    <p class="para text-muted mb-0">10 years old portal with over 1 lac retailers.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-airplay"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-rocket"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Latest Technology</h5>
                                    <p class="para text-muted mb-0">Built on advanced technology for fast transactions.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-rocket"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-clock"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Support</h5>
                                    <p class="para text-muted mb-0">Dedicated team to solve any issue in no time.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-clock"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-users-alt"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Digital Banker</h5>
                                    <p class="para text-muted mb-0">Multiple banking services under one roof.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-users-alt"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-file-alt"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>One Wallet</h5>
                                    <p class="para text-muted mb-0">Multiple services under one common wallet.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-file-alt"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-search"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Real Time Income</h5>
                                    <p class="para text-muted mb-0">Get commission as soon as the transaction is done.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-search"></i>
                                </span>
                            </div>
                        </div><!--end col-->
    
                        
                    </div><!--end row-->
                </div><!--end container-->
    
    
                <!--<div class="container mt-100 mt-60">
                    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
                        <div class="row align-items-end">
                            <div class="col-md-8">
                                <div class="section-title text-md-start text-center">
                                    <h4 class="title mb-3 text-white title-dark">Join Biznext Distributor</h4>
                                    <p class="text-white-50 mb-0">Become Biznext Distributor today and earn up to 50000 every month. </p>
                                </div>
                            </div>
                            
                            <div class="col-md-4 mt-4 mt-sm-0">
                                <div class="text-md-end text-center">
                                    <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
    
                
            </section>


            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/application.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                                    <div class="col-lg-8 col-md-7 col-12">
                                        <div class="section-title">
                                            <h4 class="title title-dark text-white mb-4">Happy Pay</h4>
                                            <p class="para-desc para-dark mb-0 text-light">Become a part of the fastest growing network of Independent Business Owners and become financially independent.</p>
                                        </div>
                                        <div class="row" id="counter">
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-graduation-cap title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="500">500</span>+</h5>
                                                    <h6 class="counter-head title-dark text-light">Districts Covered</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-book-open title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="1">1</span>Lac+</h5>
                                                    <h6 class="counter-head title-dark text-light">Retail Touch Points</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-user title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="10">10</span>Lacs+</h5>
                                                    <h6 class="counter-head title-dark text-light">Monthly Unique Customers</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-english-to-chinese title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="15">15</span>Cr+</h5>
                                                    <h6 class="counter-head title-dark text-light">Daily Transaction Volume</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>