<section class="padding_200 d-table w-100 overflow-hidden" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3 ">About Us</h1>
                    <p class="para-desc text-muted" style="text-align:justify;">Happy Pay is a state-of-the-art B2B2C Digital Platform, under the parent SaaS based Company Buddica Global Solutions Pvt. Ltd. registered under the Start-up India program.</p>
                    <p class="para-desc text-muted" style="text-align:justify;">
We are a 5-year-old company currently focused on developing cutting edge technology to enable Fintech solutions. Our primary goal is to make India financially inclusive by catering to scarcity of financial connectivity in tier II/III cities of India. The organization is also serving in sectors of travel, telecommunications and e-commerce.</p>
<p class="para-desc text-muted" style="text-align:justify;">
We, at Happy Pay, enable local retail stores to offer Assisted Digital Financial Services like Cash Deposit, Cash Withdrawal, Balance Inquiry, Bill Payments, Micro ATM, Aadhaar Enabled Services (AEPS), DTH-Mobile Recharges, POS Services, SMS Payment, Insurance, Money Transfer etc.</p>
<p class="para-desc text-muted" style="text-align:justify;">
We have leveraged artificial intelligence in our technology to make these financial transactions seamless, quick and simple. We continuously keep iterating our solutions and adapting changes as per feedback of our 35,000-active partner-network to develop this secure platform which offers a significantly superior user experience.</p>
<p class="para-desc text-muted" style="text-align:justify;">
Every day we are inching closer to our goal of transforming tier II/III stores into a one-stop solution for all digital and financial services. These stores will act as an enabler to digitize cash for the customers visiting their outlets thereby empowering greater financial connectivity in semi urban and rural regions across India. We are working on enhancing the portfolio of our authorized partners by continuously adding new services where they earn maximum income with minimum investment.
</p>
                    
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class=" position-relative">
                    <div class="bg-app-shape position-relative">
                        <img src="../../../../assets/images/Image-1-About-Us.png" class="img-fluid mx-auto d-block img_shadow" alt="">
                    </div>
                    
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<div class="container mt-100 mt-60">
    <div id="pg-10-1" class="panel-grid panel-has-style">
        <div class="about-us-section2 panel-row-style panel-row-style-for-10-1">
            <div id="pgc-10-1-0" class="panel-grid-cell">
                <div id="panel-10-1-0-0"
                    class="so-panel widget widget_siteorigin-panels-builder panel-first-child panel-last-child"
                    data-index="3">
                    <div id="pl-w62e0ea1351f4f" class="panel-layout">
                        <div id="pg-w62e0ea1351f4f-0" class="panel-grid panel-has-style">
                            <div class="about-us-section2-our-mission panel-row-style panel-row-style-for-w62e0ea1351f4f-0">
                                <div id="pgc-w62e0ea1351f4f-0-0" class="panel-grid-cell">
                                    <div id="panel-w62e0ea1351f4f-0-0-0"
                                        class="so-panel widget widget_sow-image panel-first-child" data-index="0">
                                        <div class="image panel-widget-style panel-widget-style-for-w62e0ea1351f4f-0-0-0">
                                            <div class="so-widget-sow-image so-widget-sow-image-default-d6014b76747a">
    
                                                <div class="sow-image-container">
                                                    <img src="https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission.jpg"
                                                        width="690" height="430"
                                                        srcset="https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission.jpg 690w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission-300x187.jpg 300w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission-624x389.jpg 624w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission-24x15.jpg 24w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission-36x22.jpg 36w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Mission-48x30.jpg 48w"
                                                        sizes="(max-width: 690px) 100vw, 690px" alt="" loading="lazy"
                                                        class="so-widget-image">
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panel-w62e0ea1351f4f-0-0-1"
                                        class="widget_text so-panel widget widget_custom_html" data-index="1">
                                        <div
                                            class="widget_text icon panel-widget-style panel-widget-style-for-w62e0ea1351f4f-0-0-1">
                                            <div class="textwidget custom-html-widget">
                                                <div class="icon-wrapper"><i class="fa fa-bullseye custom-icon"><span
                                                            class="fix-editor">&nbsp;</span></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panel-w62e0ea1351f4f-0-0-2"
                                        class="so-panel widget widget_sow-headline panel-last-child" data-index="2">
                                        <div class="title panel-widget-style panel-widget-style-for-w62e0ea1351f4f-0-0-2">
                                            <div class="so-widget-sow-headline so-widget-sow-headline-default-c5afba01eceb">
                                                <div class="sow-headline-container ">
                                                    <h3 class="sow-headline">Our Mission</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="pgc-w62e0ea1351f4f-0-1" class="panel-grid-cell">
                                    <div id="panel-w62e0ea1351f4f-0-1-0"
                                        class="so-panel widget widget_sow-editor panel-first-child panel-last-child"
                                        data-index="3">
                                        <div
                                            class="right-side-content panel-widget-style panel-widget-style-for-w62e0ea1351f4f-0-1-0">
                                            <div class="so-widget-sow-editor so-widget-sow-editor-base">
                                                <div class="siteorigin-widget-tinymce textwidget">
                                                    <p>
                                                        To establish an Enormous payment system in a ideal networked
                                                        environment using technology to deliver payments in real time.
                                                        Empower consumers with a easy and simple methods for making payments
                                                        any time any where. To bring facilities with easy access to last
                                                        mile banking &amp; other financial products for the rural Indians.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pg-w62e0ea1351f4f-1" class="panel-grid panel-has-style">
                            <div
                                class="about-us-section2-our-mission our-vision panel-row-style panel-row-style-for-w62e0ea1351f4f-1">
                                <div id="pgc-w62e0ea1351f4f-1-0" class="panel-grid-cell">
                                    <div id="panel-w62e0ea1351f4f-1-0-0"
                                        class="so-panel widget widget_sow-image panel-first-child" data-index="4">
                                        <div class="image panel-widget-style panel-widget-style-for-w62e0ea1351f4f-1-0-0">
                                            <div class="so-widget-sow-image so-widget-sow-image-default-d6014b76747a">
    
                                                <div class="sow-image-container">
                                                    <img src="https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision.jpg"
                                                        width="690" height="430"
                                                        srcset="https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision.jpg 690w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision-300x187.jpg 300w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision-624x389.jpg 624w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision-24x15.jpg 24w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision-36x22.jpg 36w, https://sec2payindia.com/wp-content/uploads/2021/04/Our-Vision-48x30.jpg 48w"
                                                        sizes="(max-width: 690px) 100vw, 690px" alt="" loading="lazy"
                                                        class="so-widget-image">
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panel-w62e0ea1351f4f-1-0-1"
                                        class="widget_text so-panel widget widget_custom_html" data-index="5">
                                        <div
                                            class="widget_text icon panel-widget-style panel-widget-style-for-w62e0ea1351f4f-1-0-1">
                                            <div class="textwidget custom-html-widget">
                                                <div class="icon-wrapper"><i class="fa fa-eye custom-icon"><span
                                                            class="fix-editor">&nbsp;</span></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panel-w62e0ea1351f4f-1-0-2"
                                        class="so-panel widget widget_sow-headline panel-last-child" data-index="6">
                                        <div class="title panel-widget-style panel-widget-style-for-w62e0ea1351f4f-1-0-2">
                                            <div class="so-widget-sow-headline so-widget-sow-headline-default-c5afba01eceb">
                                                <div class="sow-headline-container ">
                                                    <h3 class="sow-headline">Our Vision</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="pgc-w62e0ea1351f4f-1-1" class="panel-grid-cell">
                                    <div id="panel-w62e0ea1351f4f-1-1-0"
                                        class="so-panel widget widget_sow-editor panel-first-child panel-last-child"
                                        data-index="7">
                                        <div
                                            class="right-side-content panel-widget-style panel-widget-style-for-w62e0ea1351f4f-1-1-0">
                                            <div class="so-widget-sow-editor so-widget-sow-editor-base">
                                                <div class="siteorigin-widget-tinymce textwidget">
                                                    <ul class="arrow">
                                                        <li><i class="fa fa-arrow-right custom-arrow-icon"></i>To bring facilities with an easy access to last mile banking
                                                            &amp; other financial products for the rural Indians.</li>
                                                        <li><i class="fa fa-arrow-right custom-arrow-icon"></i>To ignite trust and mutate the lives through high class
                                                            technology in social and financial inclusion.</li>
                                                        <li><i class="fa fa-arrow-right custom-arrow-icon"></i>To became India’s leading rural centric banking &amp;
                                                            financial facilities provider organization .</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><!--end container-->

<section class=" d-table w-100 footer_new_section" id="home section_footer" style="height: 80%;">
    <div class="container">
        <div class="row mt-5 align-items-center">

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="text-md-end text-center ms-lg-4">
                    <img src="../../../../assets/images/Horizontal screen.jpg" class="img-fluid" alt="">
                </div>
            </div>
            
            <div class="col-lg-6 col-md-7">
                <div class="title-heading">
                    <div class="content">
                        <span class="sub-title"></span>
                        <h3>Become an <span class="text-primary">Happy Pay </span> Merchant  </h3>
                         <p>  Provide 500+ Digital Services – “Happy Pay<span class="text-primary"> Happy Earnings </span>”</p> 
                          
                        <div class="bar"></div>
                    <p>India has a large network of merchants. We at Happy Pay intend to improve our Merchant earning potential by enabling them to grow their business and make the best out of their retail network with Happy Pay services. All you need to do is reach out to your merchant network and help them become anHappy Pay merchants and as an Happy Pay Merchants, you can also earn commission on your network’s every transaction. Happy Pay Merchantship empowers our Channel Partners to hire a number of merchants/agents who will sell Happy Pay cashless services to their customers such as merchant services (AePS, DMT, M-ATM), Recharge & BBPS, Neo-Banking, Travel & Stay Booking, Loans, Insurance, Business Solution Services, IT-Services & E-Gov. Services etc.,</p>
                    </div>
                </div>
            </div><!--end col-->

     
        </div><!--end row-->
    </div><!--end container-->
</section>

<section class="section overflow-hidden padding_class" style="padding-top:0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4">What We Do ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">The core of <span class="text-primary fw-bold">Happy Pay,</span> what we develop? How we do it? And who all can benefit from the offerings we have?</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
        <div class="row">
            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center shadow features feature-clean explore-feature rounded border-0">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="../../../../assets/images/art-and-design.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">Technology Driven</a></h5>
                            <p class="text-muted mt-3 mb-0">We provide numerous services, developed with most secured technology to retailers via android app &amp; web portal, which gives them earning opportunities maintaining the safety of their wallet.</p>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center rounded border-0 shadow features feature-clean explore-feature">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="../../../../assets/images/smartphone.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">Multiple Services</a></h5>
                            <p class="text-muted mt-3 mb-0">We provide number of financial and utility services under one common wallet. Each service with an unlimited income potential making every retailer a smart banker of his area with good monthly income.</p>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center rounded border-0 shadow features feature-clean explore-feature">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="../../../../assets/images/clock.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">We Network With</a></h5>
                            <p class="text-muted mt-3 mb-0">Retailers, Distributors and Developers, converting them to smart banking outlet. Unlimited income opportunity for people with network and Powerful REST APIs for developers to enable financial services</p>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <!-- <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                <div class="section-title">
                    <h1 class="title mb-3">Mission </h1>
                    <p class="para-desc text-muted">To build India’s largest network of independent business owners.</p>
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Learn More <i class="uil uil-angle-right-b"></i></a>
                    </div>
                </div>
            </div>

            
            <div class="col-lg-6 col-md-5 order-1 order-md-2">
                <div class="app-feature-shape-right position-relative">
                    <div class="text-center text-md-end">
                        <img src="images/app/classic03.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>-->

    <!--
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-5">
                <div class="app-feature-shape-left position-relative">
                    <div class="text-center text-md-start">
                        <img src="images/app/classic04.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>

            
            <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
                <div class="section-title">
                    <h1 class="title mb-3">Beautiful, Simple & <br> Easy to Use</h1>
                    <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                    </ul>
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Read More <i class="uil uil-angle-right-b"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>end container-->

<!-- Our User 

    <div class="container mt-100 mt-60 pb-md-5 mb-md-5">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <span class="badge rounded-pill bg-success mb-2">Reviews</span>
                    <h4 class="title mb-4">What our users says !</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Biznext</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <div class="tiny-three-item">
                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/01.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "</p>
                                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
                            </div>
                        </div>
                    </div>
                    
                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/02.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "</p>
                                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/03.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/04.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "</p>
                                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/05.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used when a text is required. "</p>
                                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="tiny-slide">
                        <div class="d-flex client-testi m-2">
                            <img src="images/client/06.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                            <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "</p>
                                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
     
</section>

<section class=" padding_head_below">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title">Founder Message</h4>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <!-- <div class="row">
            <div class="col-lg-6 col-md-6 mt-6 pt-6">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="../../../../assets/images/Vipul-Sir-Photo.png" class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                        </ul>
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Vipul Kedia</a></h5>
                        <small class="designation text-muted">Co-Founder, Happy Pay</small>
                        <p>Vipul is an MBA from NMIMS with vast knowledge and experience of the banking sector. He was the founding member of the corporate group that worked exclusively with the FI plan of the government. At present, he heads the Business Development Division and is responsible for generating novel earning opportunities for all business partners of the abounding VKV family. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mt-6 pt-6">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="../../../../assets/images/Vinay-Sir-Photo.png" class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                        </ul>
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Vinay Kedia</a></h5>
                        <small class="designation text-muted">Co-Founder, Happy Pay</small>
                        <p>Vinay Kedia, an MBA in Marketing, is the driving force behind the development of the most advanced B2B portal for all its business partners. He heads the Operations and Finance division. It is his expertise in the field of e-commerce &amp; cutting-edge technologies that have propelled the company to reach many milestones in its fascinating journey so far.</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!--end row-->

        <div class="row about-container mb-5">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <h2>Vision and Misson</h2><span class="sub-title">Vision
                            Statement</span>
                        <p>To enable ‘Learning’ bring a visible &amp; positive impact to individuals
                            &amp; organizations. To be the reliable ‘Solution Providers’ for individuals seeking skill
                            enhancement for employability.</p>
                    </div>
                    <div class="about-content"><span class="sub-title">Vision
                            Statement</span>
                        <p>Sarveksha Academy was founded to provide cost-effective &amp; result-oriented
                            services in Education &amp; Training, in consonance with it’s slogan – Excel with Technology ,
                            Empower with Education. We strive to become a leading organization providing skill-enhancement
                            training to individuals &amp; training solutions to organizations. To achieve this, we ensure
                            selection of right talent &amp; constant grooming &amp; motivation for self-satisfaction &amp;
                            improvement, make available facilities which enable efficient &amp; productive output from team
                            members &amp; taking care of customers by satisfying their needs in totality.</p><a
                            class="default-btn"><i class="flaticon-user"></i>Join
                            For Free<span></span></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-image text-center"><img alt="image"
                            style="object-fit: cover; width: 500px; height: 500px;"
                            src="assets/founder.jpg"></div>
                </div>
            </div>
        </div>


        <div style="display:none;">

        <div class="row">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title">Team #2</h4>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/05.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Krista John</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/06.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Jack John</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/01.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Roger Jackson</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/02.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Luchhi Cina</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/03.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Sophiya Cally</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="images/client/04.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
                    <div class="content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Johnny English</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div><!--end col-->
        </div> <!-- display none ---->
        </div><!--end row-->
    </div><!--end container-->
</section>