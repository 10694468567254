



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Loans</h1>
                    <p class="para-desc text-muted">
                        Happy Pay has partnered up with leading lending organisations that help you get a loan that suits you perfectly. We offer secure applications and transactions.
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/loans/main.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section class="pb-40" style="margin-top: 70px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> Loans Services </span></h4>
                   <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                  
                    <img class="text-center"src="../../../../assets/images/Recharge & Pay Bills.png" alt="image" width="210" height="200">

                    <h3>Personal Loans</h3>
                    <p style="font-size:15px">By virtue of being an unsecured form of credit, you can avail a Personal Loan without pledging any collateral with minimal documentation, and easy eligibility criteria. This kind of instant loan does not have any end-usage restrictions</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Payment Services.png" alt="image" width="210" height="200">

                    <h3>Business Loans</h3>
                    <p style="font-size:15px">Volatile market trends and increasing business needs cause demand for urgent immediate cash-in-hand. A Business Loan designed for this purpose gives you the advantage to avail unsecured credit at competitive interest rates.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Travel.png" alt="image" width="210" height="200">

                    <h3>Loan Against Property</h3>
                    <p style="font-size:15px">A Loan Against Property or LAP helps you to purchase or maintain a home, land, or other types of real estate. Being secured in nature. LAP offerings against your commercial or residential property put you in a position to use the value</p>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Banking.png" alt="image" width="210" height="200">

                    <h3>Home Loans</h3>
                    <p style="font-size:15px">Home loan is a secured loan that can be availed for buying/ constructing a residential property.Any amount of money borrowed from a bank or an NBFC for the purchase, expansion, or construction of a home at a certain fixed or floating rate</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Loans.png" alt="image" width="210" height="200">

                    <h3>Home Loan Balance Transfer</h3>
                    <p style="font-size:15px">A Home Loan Balance Transfer is a loan transfer process where you transfer your outstanding home loan balance from your existing lending institution to a new lender. It facilitates savings in terms of interest payments when you opt for a</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Business Solution Services.png" alt="image" width="210" height="200">

                    <h3>Gold Loan</h3>
                    <p style="font-size:15px">Gold loan is one of the easy-to-acquire loans that makes it the most preferred loan type among Indians. Secured by nature, one can avail a gold loan at most competitive rates and minimal documentation. Apply for instant gold loan by pledging</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section>

<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                <div class="container">
                    <div class="row mt-5 align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>