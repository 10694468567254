<div class="container mt-100 mt-60">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4"><span class="text-primary">Happy Pay</span> Business Opportunities</h4>
                <p class="text-muted para-desc mb-0 mx-auto">Our unique platform has exciting <span class="text-primary fw-bold">business opportunity</span> for everyone, be it retailer, distributor, API or Whitelable partner!</p>
            </div>
        </div><!--end col-->
    </div><!--end row-->


</div>