<section class="section mt-5" style="background-color:#fff;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">The growth journey –
                        <span class="text-primary fw-bold"> Happy Pay</span></h4>
                    <p class="text-muted para-desc mx-auto mb-0">Over the years <span class="text-primary fw-bold">Happy Pay</span> has spread its presence across the country and process &nbsp;&nbsp;&nbsp;1 lac+ transactions daily.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row" id="counter">
            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="../../../../../assets/images/Asset187.svg" class="avatar avatar-small" alt="500+ Districts – Happy Pay">
                    <h2 class="mb-0 mt-4"><span class="counter-value" data-target="500">500</span>+</h2>
                    <h6 class="counter-head text-muted">Districts Covered</h6>
                </div><!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="../../../../../assets/images/Asset189.svg" class="avatar avatar-small" alt="1 Lakh Retail Touch Points – Happy Pay">
                    <h2 class="mb-0 mt-4"><span class="counter-value" data-target="1">1</span>Lac+</h2>
                    <h6 class="counter-head text-muted">Retail Touch Points</h6>
                </div><!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="../../../../../assets/images/Asset192.svg" class="avatar avatar-small" alt="Daily Customer Transaction Volume – Happy Pay">
                    <h2 class="mb-0 mt-4"><span class="counter-value" data-target="15">15</span>Cr+</h2>
                    <h6 class="counter-head text-muted">Daily Transaction Volume</h6>
                </div><!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
                <div class="counter-box text-center">
                    <img src="../../../../../assets/images/Asset190.svg" class="avatar avatar-small" alt="Monthly Unique Customers – Happy Pay">
                    <h2 class="mb-0 mt-4"><span class="counter-value" data-target="10">10</span>Lacs+</h2>
                    <h6 class="counter-head text-muted">Monthly Unique Customers</h6>
                </div><!--end counter box-->
            </div>
        </div><!--end row-->
    </div><!--end container-->

 
</section>