



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Loans &amp; Insurance</h1>
                    <p class="para-desc text-muted">
                        Happy Pay has partnered up with leading lending organisations that help you get a loan that suits you perfectly. We offer secure applications and transactions.
                        <br />
                        Happy Pay has also integrated with top insurance providers in the country to enable a host of different insurance products for all its partners with the maximum commission.
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/loans/main.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section style="margin-top: 70px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> Loans Services </span></h4>
                   <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/personal-loans.jpg" alt="image" width="210" height="200">

                    <h3>Personal Loans</h3>
                    <p style="font-size:15px">Personal loan. Get an instant approval with lowest interest rates and flexible personal loan.<br />&nbsp;</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/business-loans.jpg" alt="image" width="210" height="200">

                    <h3>Business Loans</h3>
                    <p style="font-size:15px">A business loan is a financial offering that can be borrowed to meet your planned and unplanned business expenses.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/home-loans.jpg" alt="image" width="210" height="200">

                    <h3>Home Loans</h3>
                    <p style="font-size:15px">
                        A home loan is a secured loan taken from a financial institution for the purpose of buying a residential property.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section>

<section style="margin-top: 70px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> Insurances </span></h4>
                   <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/health-insurance.jpg" alt="image" width="210" height="200">

                    <h3>Health Insurance</h3>
                    <p style="font-size:15px">A medical insurance policy, also called as health insurance, covers medical expenses for illnesses or injuries. It reimburses your bills</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/motor-insurance.jpg" alt="image" width="210" height="200">

                    <h3>Motor Insurance</h3>
                    <p style="font-size:15px">Motor insurance is an insurance policy for four-wheelers, commercial trucks, two-wheelers, and other road vehicles</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center" src="../../../../assets/images/services/loans-insurances/shop-insurance.jpg" alt="image" width="210" height="200">

                    <h3>Shop Insurance</h3>
                    <p style="font-size:15px">DA shop insurance is a type of insurance policy designed to cover for a shop's property and its contents within.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section>

<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 0px 0px 55px 0px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>