



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Banking Services</h1>
                    <p class="para-desc text-muted">Accept payments smartly and safely with mobile via QR code/UPI, Credit/Debit cards via mPos/ dPos and collect via link
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/merchantServices/merchant-services-main.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<section class="padding_200 " id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/merchantServices/mPos.png" class="max-width-70 rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">mPOS & dPOS</h1>
                    <p class="para-desc text-muted">Using our mPOS (Mobile point of sale) service and dPOS, you can easily accept payment requests from your customers using the Debit and Credit of all the banks. This not only gives your customers more options to pay you money but also provides you with an opportunity to earn attractive commissions. So what are you waiting for? Go and download the Happy Pay merchant app now !
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

      <!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>






<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Collect Via Link</h1>
                    <p class="para-desc text-muted">In the absence of cash and any debit or credit cards, your customers can still pay you using a Payment link. A payment link is basically a way of requesting payment from your customers when they want to perform transactions outside the realm of the traditional online payment system. So now your customer can pay you anytime and from anywhere using Credit cards, Debit cards, Net banking etc


                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/merchantServices/collectViaLink.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>




<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/merchantServices/qr.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">My QR/UPI</h1>
                    <p class="para-desc text-muted">Happy Pay brings you one more attractive option of increasing your options of accepting payments in the form of QR codes or UPI. Basically QR code is a contactless payment service that enables your customers to simply scan a code from their smartphone and complete the transaction. The service is highly reliable, safe, and secure and the transaction is performed almost instantly


                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

   
        </div><!--end row-->
    </div><!--end container--> 
</section>
<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 0px 0px 55px 0px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>