

<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/utility-recharge.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Utility Recharge</h1>
                    <p class="para-desc text-muted">Gone are the days when anyone used to stand in a long queue, waiting for hours just for filling their monthly bills namely Electricity, Water, and Gas. With the Happy Pay merchant app in your mobile, you can ease your customer’s life and save their time by giving them the option of filling their monthly bill/ dues through your smartphone, Happy Pay merchant app, and most importantly through you !
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div>
                </div>
            </div><!--end col-->

   
        </div><!--end row-->
    </div><!--end container--> 
</section>


<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
    <div class="col-12">
        <h2 class="section-service-head">Utility Bill Payments</h2>
        <p class="section-service-content">Enable customers to make utility bill payments easily at your
            establishment by becoming a Happy Pay partner service provider. Customers can avail facilities to
            make
            electricity bill payments, mobile bill payments, gas bill payments, postpaid bill payments,
            water bill
            payments and other utilities in the one-click process. Register now to become a utility bill
            payment
            agent with Happy Pay Banking services to gain happy customers and earn monthly profits from the high
            commissions.
        </p>
        <h3 class="section-service-topic">No Wait Time To Pay Electricity Bills</h3>
        <p class="section-service-content">Allow your customers the ease and benefit of paying their
            electricity
            bills from your establishment, and you don’t just enjoy the extra footfall, but also gain
            through agent
            commission. The payment method allows customers to make their electricity bills online or
            through your
            establishment. Convert your retail outlet into an electricity bill payment business with Happy Pay
            and
            register now to become an electricity bill payment agent. Enjoy wholesome rewards like high
            commissions,
            cashback to your customers and a lot more!
        </p>
        <h3 class="section-service-topic">Renew And Pay Gas Bills Easily</h3>
        <p class="section-service-content">Gone are the days when one had to wait in long lines to be able
            for
            recharging or subscribing for gas cylinders. With the Happy Pay merchant app, your customers can
            easily renew
            their subscriptions or pay gas bills without having to wait in line or travel long distances.
            Customers
            can pay their gas bills using digital payments or other modes while you gain BBPS agent
            commission to
            get wholesome rewards and happy customers!
        </p>
        <h3 class="section-service-topic">Save Time And Pay All Your Bills Easily</h3>
        <p class="section-service-content">Happy Pay Merchant App is perfect for making any bill payments and
            supports
            several top digital payment options and banks to help you become a complete utility bill payment
            agent.
            Not just water bills, electricity bills or other utilities, your customers can easily make
            payments for
            mobile bill payment, landline bills and other facilities. To become a partner, all you need is a
            shop
            with a mobile or laptop/computer to get started!
        </p>
        <h3 class="section-service-topic">Generate Extra Revenue As A Utility Bill Payment agent</h3>
        <p class="section-service-content">As a utility bill payment agent, you can enable customers to
            recharge,
            pay bills and provide additional services that are quick and secure. Retailers can get the
            advantages
            like:</p>
        <div style="padding-left: 20px;">
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                High-speed services for providing utility bill payments
            </p>
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                Hassle-free bill payment without requiring any third-party intervention
            </p>
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                Easy to use app that allows retailers to get started right away
            </p>
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                Enjoy higher footfall as customers will prefer to come to your establishment rather than
                travel long
                distances to make bill payments
            </p>
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                Earn high commissions with each utility bill payment, making it a win-win for retailers
            </p>
            <p class="section-service-content"><span style="font-size:12px; padding-right: 10px;">●</span>
                Next, stick the applicant’s photo in the form and request the applicant to sign.
            </p>
        </div>
        <p class="section-service-content">The app is easy to use and allows retailers to instantly pay your
            customers’ bills online at the click of a button. In case there are any issues, you can quickly
            get
            support on multiple platforms like telephone or SMS to get it resolved quickly.<br><br>
            This makes the Happy Pay merchant app the most secure and trusted service in India and lets you
            provide
            customers with on-demand services from the comfort of your shop!

        </p>
    </div>
</div>
</div>
</section>

            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>