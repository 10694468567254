



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Travels & Bookings</h1>
                    <p class="para-desc text-muted">You can earn profitable commissions by booking air and trains tickets or hotels easily and double up your monthly earnings. You can also earn commissions by Provide Insurance & PAN card service to your customers
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/travel/travel-main.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<section class="padding_200 " id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/travel/irctc-booking.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">IRCTC Ticket Booking</h1>
                    <p class="para-desc text-muted">We are proud to say that we are one of the few vendors who are authorized from IRCTC to book train tickets. Now you can easily book tickets online for any train, class, or destination. With us, you don’t need to worry about the status of your booking because our service is highly fast, safe, simple and reliable. So before you miss on to your favorite berth, download our app today !
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

      <!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>






<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Flight Booking</h1>
                    <p class="para-desc text-muted">First flight journeys are always memorable for any person in their lives. Now make this special moment for your customer even more special by providing them the seamless experience of booking their flight ticket. With the Happy Pay merchant app, you can book flight tickets both domestic and international from your phone. Our dedicated team of customer service agents makes sure that this process is hassle-free for you and your customers
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/travel/flight-booking.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>




<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="../../../../assets/images/services/travel/hotel-booking.svg" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Hotel Booking</h1>
                    <p class="para-desc text-muted">Happy Pay has tie-ups with various hotel chains and travel agencies across the country. So with the Happy Pay merchant app, you get the power of accessing and browsing through the various plans and features of these hotel chains and travel agencies and then choose the best option for you and your customer. So, for next time, don’t forget to use our app to check in to a new hotel
                    </p>
                    <!-- <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> KNOW MORE</a>
                    </div> -->
                </div>
            </div><!--end col-->

   
        </div><!--end row-->
    </div><!--end container--> 
</section>
<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                <div class="container">
                    <div class="row mt-5 align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>