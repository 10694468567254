<app-modern-schooling-banner></app-modern-schooling-banner>

<!-- <app-boxes></app-boxes> -->

<!-- <app-modern-schooling-about></app-modern-schooling-about> -->

 <!-- <app-overview></app-overview>  -->
 <!-- <app-feedback></app-feedback> -->

 
 <section class="padding_50 bg-light">
    <div class="container">
        
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2"> 
                    <h4 class="title mb-4">Why Happy Pay?</h4>
                    <p class="text-muted para-desc mb-0 mx-auto"><span class="fw-bold text-primary">Happy Pay</span> offers many advantages which makes it one of the best option for anyone who is looking to start a business</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="row mt-4 pt-2">
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor fea icon-ex-md text-primary"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">One Wallet </h4>
                                <p class="text-muted para mb-0">One wallet for all your services, no need to maintain funds in separate wallets, the same wallet acts as your settlement a/c as well</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-feather fea icon-ex-md text-primary"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Multiple Services </h4>
                                <p class="text-muted para mb-0">Opportunity to increase your revenue multiple times as the portal is equipped with multiple services across different domains</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye fea icon-ex-md text-primary"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Working Capital </h4>
                                <p class="text-muted para mb-0">Our unique business model is plug and play experience where you can start your own business at minimal or Zero working capital</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-ex-md text-primary"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Artificial Intelligence </h4>
                                <p class="text-muted para mb-0">We have leveraged artificial intelligent so that the users get to enjoy lowest failures and fastest transactions in the industry</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone fea icon-ex-md text-primary"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Technology</h4>
                                <p class="text-muted para mb-0">We pride our self in using the latest technology so that you always enjoy seamless, fast &amp; secured transactions &amp; increase your revenue</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart fea icon-ex-md text-primary"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">Dedicated Team </h4>
                                <p class="text-muted para mb-0">We are proud of our support team &amp; relationship managers who will walk hand in hand with you throughout the journey &amp; ensure maximum income</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->

            <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
                <img src="../../../../assets/images/mode.png" class="img-fluid " alt="Happy Pay Services">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>


 <div style="width: 100%;display: flex;">
    <img src="https://aeronpay.in/assets/images/5.jpg" style="width: 50%; height: 100%;" />
    <div style="padding: 40px; display: flex; flex-direction: column; align-items: flex-start;">
        <h1 style="color: black; font-size: x-large; font-weight: 900;">Hassle-Free Rent Payment on Happy pay</h1>
        <p style="color: black;">Using QR codes for rent payments presents a modern and efficient solution that benefits both landlords and tenants alike. 
            For tenants, QR code payments offer unparalleled convenience as they can easily initiate payments using their smartphones. 
            This eliminates the need for physical checks or cash, streamlining the process and saving time. 
            The instantaneous nature of QR code transactions ensures that payments are processed swiftly, providing immediate confirmation to both parties. 
            Additionally, QR codes enhance security by encrypting payment information, reducing the risks associated with traditional payment methods. 
            For landlords, QR code payments simplify reconciliation and recordkeeping, as digital records of transactions are automatically generated. 
            This not only improves financial management but also ensures accuracy in tracking rental payments. 
            Overall, QR code payments for rent foster a more streamlined, secure, and transparent payment experience, 
            aligning with the digital transformation of financial transactions in today's increasingly digital world.</p>
    </div>
 </div>

<app-modern-schooling-courses></app-modern-schooling-courses>
 


<!-- <app-top-categories></app-top-categories> -->

<!-- <div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div> -->



<app-modern-schooling-events></app-modern-schooling-events> 

<!-- <app-modern-schooling-about></app-modern-schooling-about> -->

<!-- <div class="subscribe-area ">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="https://odemy-ng.envytheme.com/assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="https://odemy-ng.envytheme.com/assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="https://odemy-ng.envytheme.com/assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="https://odemy-ng.envytheme.com/assets/img/shape14.png" alt="image"></div>
</div> -->

