

<div class="pb-10"  style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:20px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
            <h3 style="text-align: center;color:#fff;">Become Happy Pay Partner &amp; Earn upto Rs.50,000 per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary" >Know More</div>
                        </a>
                    </div>
            </div>

			<section class="pb-30 pt-70">

<!-- 
<div class=" pb-70 pt-70 boxes-area boxes-style-two bg-f5f7fa pb-5">
    <div class="containeer">

	
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<div class="section-title mb-4 pb-2">
						<h4 class="title mb-4"><span class="text-primary">Happy Pay</span> Services</h4>
						 <p class="text-muted para-desc mb-0 mx-auto">Our unique platform has exciting <span class="text-primary fw-bold">business opportunity</span> for everyone, be it retailer, distributor, API or Whitelable partner!</p>-->
					<!-- </div> -->
				<!-- </div> -->
			<!-- </div> -->
		<!-- <div class="single-funfacts-item with-box-shadow"> -->

			<!-- <div class="item-all-cat center-block text-center education-categories">
				<div class="row">

					<div class="itemCard col-lg-2 col-md-4 col-sm-6 " >
						<div class="item-all-card text-dark text-center" >
							<a routerLink="/rechargeservices">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/1. Recharge.png">
                            <div class="iteam-all-icon">
								<i class="fa fa-mobile gradient-icon"></i>
							</div></a>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/rechargeservices">Recharge Services</a></h5>
							</div>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark text-center">
							<a routerLink="/billpayments">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/2. Bill payments.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-television  gradient-icon "></i>
							</div>
						
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/billpayments">Bill Payments</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6 " >
						<div class="item-all-card text-dark text-center">
							<a routerLink="/banking">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/3. Banking.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-wifi gradient-icon"></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/banking">Banking</a></h5>
							</div>
							</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6 " >
						<div class="item-all-card text-dark text-center">
							<a routerLink="/payment">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/4. Payment.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-signal gradient-icon"></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/payment">Payment</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark text-center">
							<a routerLink="/travel">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/5. All Travels.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-volume-control-phone gradient-icon "></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/travel">All Travels</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark item-all-textn  text-center">
							<a routerLink="/loans">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/6. Loans.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-television  gradient-icon "></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/loans">Loans</a></h5>
							</div>
							</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark item-all-textn  text-center">
							<a routerLink="/insurance">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/7. Insurance.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-lightbulb-o gradient-icon"></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/insurance">Insurance</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark item-all-textn  text-center mb-10">
							<a routerLink="/investiments">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/8. Investments.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-train gradient-icon"></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/investiments">Investiments</a></h5>
							</div>
							</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark item-all-textn text-center mb-md-0">
							<a routerLink="/business">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/9. Business.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-rocket gradient-icon "></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/business">Business</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark  item-all-textn text-center">
							<a routerLink="/governance">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/10. E- Governance.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-pied-piper-alt gradient-icon  fa-3x"></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/governance">E-Governance</a></h5>
							</div>
							</a>
						</div>
					</div>
						<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark  item-all-textn  text-center">
							<a routerLink="/itservices">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/11. IT services.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-hotel  gradient-icon "></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0"><a routerLink="/itservices">IT Services</a></h5>
							</div>
						</a>
						</div>
					</div>
					<div class="itemCard col-lg-2 col-md-4 col-sm-6">
						<div class="item-all-card text-dark item-all-textn text-center">
							<a routerLink="/amazoneasy">
                            <img class="img-fluid rounded" alt="" src="../../../../assets/images/12. Amazon easy.png">
							
							<div class="iteam-all-icon">
								<i class="fa fa-plane  gradient-icon "></i>
							</div>
							<div class="item-all-text mt-3">
								<h5 class="mb-0">	<a routerLink="/amazoneasy">Amazon Easy</a></h5>
							</div>
							</a>
						</div>
					</div> 
				</div>
			</div>

	 -->
        <!-- <div class="row justify-content-center">
            <div *ngFor="let item of cards" class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img [src]="item.image" alt="image">
                    </div>
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                    <a routerLink="/courses-1" class="default-btn" style="padding-left: 35px;">Start Now!</a>
                </div>
            </div>
        </div>
	</div>     
    </div>
</div> -->




</section>
<section class="pb-30 pt-30">
<div class="boxes-area boxes-style-two bg-f5f7fa pb-5">
    <div class="container">
		

			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<div class="section-title">
						<h1 class="title mb-4" style="font-size:large"><span class="text-primary"> Happy Pay – </span> Services </h1>
					</div>
				</div>
			</div>
		

	<div class="row justify-content-center">
		<div class="col-md-4 mt-4 pt-2">
			<ul class="nav nav-pills nav-justified flex-column bg-white rounded shadow p-3 mb-0 sticky-bar" id="pills-tab" role="tablist">
				<li class="nav-item">
					<a class="nav-link rounded active" id="proposal" data-bs-toggle="pill" href="#developing" role="tab" aria-controls="developing" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-postcard me-2 h5"></i>Banking Services</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="contract" data-bs-toggle="pill" href="#data-analise" role="tab" aria-controls="data-analise" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-notes me-2 h5"></i>Neo Banking Services</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="crm" data-bs-toggle="pill" href="#security" role="tab" aria-controls="security" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-folder-check me-2 h5"></i>Utility Payment Center</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="timetracking" data-bs-toggle="pill" href="#time-track" role="tab" aria-controls="time-track" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-clock me-2 h5"></i>Loans & Insurance</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="invoice" data-bs-toggle="pill" href="#invoices" role="tab" aria-controls="invoices" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-invoice me-2 h5"></i>Travels & Bookings</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="tasktracking" data-bs-toggle="pill" href="#task-track" role="tab" aria-controls="task-track" aria-selected="false">
						<div class="text-start border-bottom d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil uil-exchange-alt me-2 h5"></i>CA &amp; Legal Services</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
				
				<li class="nav-item mt-2">
					<a class="nav-link rounded" id="prepaid" data-bs-toggle="pill" href="#pre-paid" role="tab" aria-controls="pre-paid" aria-selected="false">
						<div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
							<h6 class="mb-0"><i class="uil-card-atm me-2 h5"></i>Add-on Partner Services</h6>
							<i class="uil uil-angle-right-b"></i>
						</div>
					</a>
				</li>
			</ul>
		</div>

		<div class="col-md-8 col-12 mt-4 pt-2">
			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade bg-white p-4 show active rounded shadow" style="padding: 40px 40px 0 0px;" id="developing" role="tabpanel" aria-labelledby="proposal">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Banking Services</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Go digital. From AePS, DMT, Mini ATM, Prepaid cards, UPI QR to SMS payments and Aadhaar Pay, let us equip you with the latest digital payment tools. Use our digital ledger, Customer Khata to manage your customer credits better.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/DS.png" class="img-fluidd" alt="Aadhaar Enabled Payment Services – AEPS">
						</div>
					</div>
				</div>
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="contract">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Neo Banking Services</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Customers can withdraw money, deposit cash, transfer money, get a loan or make secure savings from your shop. Join the network that services a million financial transactions per day. And in every service you give, you earn. Simple and effective.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/banking.png" class="img-fluidd" >
						</div>
					</div>
				</div>
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="security" role="tabpanel" aria-labelledby="crm">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Utility Payment centre</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Households need to pay their EMIs and utility bills every month. Solve customer pain point of standing in long queues. From water bills to electricity bills, from phone/DTH recharges to monthly EMIs, provide easy cash collection, bill payment and recharge solutions from your shop.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/utility.png" class="img-fluidd" >
						</div>
					</div>
				</div>
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="time-track" role="tabpanel" aria-labelledby="timetracking">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Loans & Insurance</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Less than 3% of Bharat has an insurance. Protect your area with affordable insurance plans across health, medical and general insurance.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/insurance.png" class="img-fluidd" >
						</div>
					</div>
				</div>
				
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="invoices" role="tabpanel" aria-labelledby="invoice">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Travels & Bookings</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Offer a range of affordable travel solutions from your shop: bus, rail, flight, hotels, holidays and more.  An IATA approved travel service provider, we offer you the most comprehensive range of travel products and services at very competitive rates.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/travel.png" class="img-fluidd">
						</div>
					</div>
				</div>
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="task-track" role="tabpanel" aria-labelledby="tasktracking">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">CA &amp; Legal  Services</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Issue paperless PAN, GST, Registrations, Taxation & Complaince and IT Services for customers from your shop. Make essential documentation services available and bring them into the formal financial fold</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/PS.png" class="img-fluidd" >
						</div>
					</div>
				</div>
				<div class="tab-pane fade bg-white p-4 rounded shadow" id="pre-paid" role="tabpanel" aria-labelledby="prepaid">
					<div class="tab-body" style="display: flex;">
						<div>
							<h4 class="mb-4" style="font-size: 40px; font-weight: 700;">Add-on Partner Services</h4>
							<p class="text-muted mb-0" style="font-size: 20px; font-weight: 500;">Customers in urban interiors and rural areas are underserved on financial as well as digital products. Help your customers access the digital product of our partner brands, across ecommerce, gaming, education, music, video and many more.  Earn on every transaction.</p>
							<div class="mt-4">
								<a (click)="login()" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> KNOW MORE<span></span></a>
							</div>
						</div>
						<div class="mt-4">
							<img src="../../../../assets/ES.png" class="img-fluidd">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div>

</section>