 <!--<div class="hero-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="hero-banner-content">
                 
                    <h1>We Made </h1>
                    <h1>Payments More</h1>
                    <h1 style="color: #10b982 !important;">Easier Now</h1>
                    <a (click)="login()" class="default-btn"><i class="flaticon-user"></i>Link Your Bank Account<span></span></a>
                    &nbsp;&nbsp;&nbsp; <a (click)="login()" class="default-btn"><i class="flaticon-user"></i>Sign Up<span></span></a>
                </div>
            </div>

            	

            <div class="col-lg-6 col-md-12">
                <div class="">
                    <img src="../assets/images/homebanner.png"  alt="img" class="">
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image">
        <div class="banner-shape4"><img src="assets/img/banner-shape4.png" alt="image"></div>
        <div class="banner-shape5"><img src="assets/img/banner-shape5.png" alt="image"></div>
        <div class="banner-shape6"><img src="assets/img/banner-shape6.png" alt="image"></div>
        <div class="banner-shape7"><img src="assets/img/banner-shape7.png" alt="image"></div>
    </div>
    <div class="divider"></div>
</div>-->



<section class="  bg-half-170 pb-0 bg-light w-100 overflow-hidden padding_head_below" style="background: url('images/shapes/shape2.png') top; z-index: 0;">
    <div class="">
        <div class="container-fluid">
    <div class="container">
        <div class="row align-items-center mt-5 mt-sm-0">
            <div class="col-md-6">
                <div class="title-heading text-center text-md-start">
                    <div class="hero-banner-content">
                        <!-- <h1>{{title}}</h1> -->
                        <h1>Happy Pay </h1>
                        <h1 style="color: #10b982 !important;">Happy Earnings</h1>
                        <h2 className="banner-para">Grow your business with HappyPay. We can help you achieve your business goals.
                            Pay whenever you like, wherever you like. Upgrade to HappyPay super app and start unlimited earnings</h2>
                        <!-- <h2><i class="fa fa-arrow-right custom-arrow-icon"></i>&nbsp;&nbsp;Upgrade Your Shop. By Providing 500+ Digital Services</h2>
                        <h2><i class="fa fa-arrow-right custom-arrow-icon"></i>&nbsp;&nbsp;Start with Zero Investment & Earn Upto Rs 50,000 Monthly</h2>
                        <h2><i class="fa fa-arrow-right custom-arrow-icon"></i>&nbsp;
                            Become the one stop digital &amp; financial service centre in your <br/>area and service your customers better.
                        </h2> -->
                        <!-- <a (click)="login()" class="default-btn"><i class="flaticon-user"></i>Link Your Bank Account<span></span></a> -->
                        <a href="https://play.google.com/store/apps/details?id=happy.pay.com" class="default-btn default-buttonspace text-center"><i class="flaticon-user"></i> Get Started<span></span></a>
                        <a href="/income-calculator" class="default-btn default-buttonspace income-calculator-btn text-center"><i class="flaticon-user"></i> Income Calulator<span></span></a>
                    </div>
                    <!-- <h4 class="heading mb-3 mt-2">Digital India Ka Ye Naya Manch, <span class="">Mere Desh Ka Main DigiPanch!</span></h4>
                    <p class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto">Become a part of the fastest growing network of Independent Business Owners and become financially independent.</p>
                    
                    <div class="mt-4">
                        <a href="https://partner.biznext.in/signup" target="_blank" class="btn btn-primary" style="margin:10px 0px;">Free Agent Sign Up</a>
                        <a href="#lead_form" class="btn btn-primary" style="margin:10px 0px;">Distributor Account</a>
                        
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="freelance-hero position-relative">
                    <div class="bg-shape position-relative">
                        <img src="../../../../../assets/images/homescreen/Manage money-amico.png" style="max-width: 91%;" class="mx-auto d-block img-fluid" alt="Happy Pay Agent">
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="banner-image">
        <div class="banner-shape5"><img src="assets/img/banner-shape5.png" alt="image"></div>
    </div>
    <div class="divider"></div>
</div>
</div>
</section>
