import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-bookings',
  templateUrl: './travel-bookings.component.html',
  styleUrls: ['./travel-bookings.component.scss']
})
export class TravelBookingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
