<section class="padding_200 d-table w-100"  id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading mt-4">
                   <h1 class="heading mb-3"> Lots of  <span class="fw-bold text-primary">Utility Services</span> Under One Roof!</h1>
                    <p class="text-muted">Biznext enables you to offer numerous utility services from your store and increase your daily income.</p>

                    <p class="text-muted">Customer Mobile and DTH Recharge, Electricity, Gas and 30+ such bill payments, PAN Card Creation services are offered in Biznext panel to help the partner attract more customers and increase their service portfolio while earning money on each transaction.</p>


                    <p class="text-muted "><a href="#lead_form" class="btn btn-primary">Multi recharge distributorship, all in one recharge distributor</a></p>
                    
                    <!--<div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-pills btn-primary m-1">Get Started</a>
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1 lightbox"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video icons"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg></a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
                    </div> -->
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/images/seo.gif" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

<div class="pb-70"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Retailer and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>

            <section class="">

                <div class="container mt-100 mt-60">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <img src="../../../../assets/images/Utility-2.png" alt="" class="img-fluid">
                            </div><!--end col-->
        
                            <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2">
                                <div class="section-title">
                                    <h4 class="title mb-4">Bill Payment, Multi Recharge Distributionship and PAN Card Service</h4>
                                    <p class="text-muted">Biznext offers various utility services such as Recharge, Bill Payments and providing Pan Card. Multi recharge distributors can now do mobile and DTH recharge using our advanced portal and earn commission per transaction. Pay bills of more than 100 billers in India including all the major electricity, gas, water and telecom operators all through our all in one recharge portal which uses Bharat Bill Pay (BBPS) at the click of a button, using a single wallet. Biznext now has strategized a new and unique feature to increase your earnings manifold. </p>
        
                                    <p class="text-muted">You can now provide a PAN Card of customers at your retail store. Such PAN Card generation service is available exclusively for the retailers at Biznext portal. Conduct your business through Biznext smoothly and save inventory costs and lots of effort and time.</p>
                                    
                                </div>
                            </div><!--end col-->
                      </div><!--end row-->
                </div>
        
        
        
        
        
        
                <div class="container mt-100 mt-60">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div class="section-title me-lg-5">
                                    <h4 class="title mb-4"><span class="text-primary fw-bold">Biznext</span> Multi Recharge Distributorship</h4>
                                    <p class="text-muted">We, at Biznext as multi recharge company provide a unique solution for small businesses to become an all in one recharge distributor with very less investment and sizeable returns. Biznext provides multi recharge business, including all companies Airtel, Jio, Idea, Vodafone, Tata Sky, Dish TV and more under a single umbrella. Even if you have no experience in this area of business, we are offering you a perfect opportunity to boost your income. We maintain 99%+ uptime system and a dedicated team to take care of all the problems faced by you.</p>
        
                                    <p class="text-muted">To avail the benefits of Biznext Multi Recharge Distributorship, all you need to do is register with us. As a distributor, you can find people in your network who have the requirement for Bulk recharge and leverage your network partners to earn extra money. As a recharge distributorship, you get to leverage your existing network of distributors and mobile or DTH recharge retailers to ensure maximum transactions which will in turn get you a handsome income.</p>
        
        
        
                                
                                </div>
                            </div><!--end col-->
        
                            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                                <img src="../../../../assets/images/Utility-3.png" alt="" class="img-fluid">
                            </div><!--end col-->
                        </div><!--end row-->
                    </div>
        
        
        </section>






        <section class="">

            <div class="container mt-100 mt-60">
                       <div class="row justify-content-center">
                           <div class="col-12 text-center">
                               <div class="section-title mb-4 pb-2">
                                   <h4 class="title mb-4">You Have Any <span class="text-primary fw-bold">Questions?</span></h4>
                                   
                                   </div>
                           </div><!--end col-->
                        </div><!--end row-->
       
                       <div class="container mt-50 mt-60">
                       <div class="row align-items-center">
                           <div class="col-lg-7 col-md-6">
                               <div class="accordion" id="accordionExample">
                                   
       
       
       
       
                                   <div class="accordion-item rounded shadow">
                                       <h2 class="accordion-header" id="headingOne-">
                                           <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-" aria-expanded="true" aria-controls="collapseOne-">Best Mobile App for Bill Payment Services
                                           </button>
                                       </h2>
                                       <div id="collapseOne-" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne-" data-bs-parent="#accordionExample">
                                           <div class="accordion-body text-muted bg-white">
                                              <p>Bharat BillPay (BBPS) - powered bill payment service is available in the portal through which you can easily pay bills of more than 100 billers in India including all the major electricity, gas, water or telecom operators. Be it electricity, gas, telecom, cable tv, fastag, health insurance, municipal taxes, education etc. all customer bills can be paid at the click of a button.</p>
                                               
                                           </div>
                                       </div>
                                   </div>
       
       
       
       
       
                                   <div class="accordion-item rounded shadow">
                                       <h2 class="accordion-header" id="headingOne">
                                           <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">PAN Card Provider
                                           </button>
                                       </h2>
                                       <div id="collapseOne" class="accordion-collapse border-0 collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                           <div class="accordion-body text-muted bg-white">
                                              <p>On popular demand by existing Biznext partners, Biznext has come up with PAN Card providing service where any walk-in customer can apply for a PAN Card and provide details and their PAN Card will be generated. PAN Card is one document which is required by everyone for tax purposes as well as an identity proof. Since such a service is required by everyone, there is lots of earning opportunity with the PAN Card service.</p>
                                           </div>
                                       </div>
                                   </div>
                                   
                                   <div class="accordion-item rounded shadow mt-2">
                                       <h2 class="accordion-header" id="headingTwo">
                                           <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                               What does distributor get?
                                           </button>
                                       </h2>
                                       <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                           <div class="accordion-body text-muted bg-white">
                                               <p>Biznext Distributor gets real time commission on each transaction done by anyone who is a part of their Biznext network. The best part of being Biznext multi recharge distributor is that you get to decide how much commission you want to pass on to different categories of recharge retailer in your network. It will work completely on your choice and you can edit it any time according to your will. Up to 6% commission is given on recharge, it varies according to different brands. Add your own agent network to Biznext and earn infinite commissions from your Agents, on recharges done by them.</p>
                                           </div>
                                       </div>
                                   </div>
       
                                   <div class="accordion-item rounded shadow mt-2">
                                       <h2 class="accordion-header" id="headingThree">
                                           <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                               How to Apply?
                                           </button>
                                       </h2>
                                       <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                           <div class="accordion-body text-muted bg-white">
                                             <p> To avail our services for becoming all in one recharge distributor, apply online for Biznext registration by filling the online application form. The form will be received and verified by our executives. Verification Process will be done by our team. Once verified, you are ready to use our recharge services.</p>
                                           </div>
                                       </div>
                                   </div>
       
                                   
                               </div>
                           </div><!--end col-->
       
                           <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                               <img src="../../../../assets/images/faq.svg" alt="">
                           </div><!--end col-->
                       </div><!--end row-->
                   </div>
               </div>
       
       
       
       
       
       
              
       
       
       
       
                   <div class="container mt-100 mt-60">
                       <div class="row align-items-center">
                           <div class="col-md-6">
                               <img src="../../../../assets/images/who-can-start.jpg" class="img-fluid" alt="">
                           </div><!--end col-->
       
                           <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                               <div class="section-title">
                                   
                                   <h4 class="title mb-4">Who can start <span class="text-primary fw-bold">Multi Recharge</span> Business?</h4>
       
       
                                   <ul class="list-unstyled text-muted">
                                       <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Retailer Shop Owners</li>
                                       <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Business Owners</li>
                                       <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Students</li>
       
                                       <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Housewives</li>
       
                                       <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Any person who wants to earn money</li>
       
                                   </ul>
       
       
                                   <p class="text-muted">You can start by registering yourself as a Biznext recharge retailer or recharge distributor, using only your PAN Card and Aadhar Card. You can now do DTH &amp; mobile recharge using only a smart phone with an active internet connection using the smart Biznext mobile application at the click of a button. Alternately, you can also use desktop if you are more comfortable with it using Biznext Web login.</p>
       
                                   
                               </div>
                           </div><!--end col-->
                       </div><!--end row-->
                   </div>
       
       
       
       
       </section>




            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/mode.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>