import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-income-calculator',
  templateUrl: './income-calculator.component.html',
  styleUrls: ['./income-calculator.component.scss']
})

export class IncomeCalculatorComponent implements OnInit {

  data: IncomeCalculatorCategories[] = [
    {
      mainHeader: 'Neo Banking Services',
      body: [
        {
          header: 'Banking Services',
          type: 'Daily',
          body: [
            {
              title: 'Digi Smart Transfer',
              avgTrnx: '3000',
              transactionsCount: 1,
              income: 18.60
            },
            {
              title: 'Money Transfer',
              avgTrnx: '4200',
              transactionsCount: 1,
              income: 20.16
            },
            {
              title: 'Aadhar ATM - AePS',
              avgTrnx: '3500',
              transactionsCount: 1,
              income: 7
            },
            {
              title: 'Micro ATM',
              avgTrnx: '3250',
              transactionsCount: 1,
              income: 7
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'Utility Services',
      body: [
        {
          header: 'Utility Services',
          type: 'Daily',
          body: [
            {
              title: 'Mobile Recharge',
              avgTrnx: '300',
              transactionsCount: 1,
              income: 3
            },
            {
              title: 'DTH Recharge',
              avgTrnx: '200',
              transactionsCount: 1,
              income: 4
            },
            {
              title: 'Bill Payments',
              avgTrnx: '800',
              transactionsCount: 1,
              income: 0.64
            }
          ]
        },
        {
          header:'All Types of Insurance',
          type: 'Daily',
          body: [
            {
              title: 'Savings Khata Insurance',
              avgTrnx: '1000',
              transactionsCount: 1,
              income: 65
            },
            {
              title: 'Bike Insurance',
              avgTrnx: '1682',
              transactionsCount: 1,
              income: 55
            },
            {
              title: 'Group Personal Accident',
              avgTrnx: '590',
              transactionsCount: 1,
              income: 60
            },
            {
              title: 'HospiCash',
              avgTrnx: '1180',
              transactionsCount: 1,
              income: 140
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'Travels & Stay',
      body: [
        {
          header: 'Travel & Stay',
          type: 'Daily',
          body: [
            {
              title: 'Bus',
              avgTrnx: '500',
              transactionsCount: 1,
              income: 20
            },
            {
              title: 'Flight',
              avgTrnx: '3500',
              transactionsCount: 1,
              income: 42
            },
            {
              title: 'Hotel Bookings',
              avgTrnx: '2000',
              transactionsCount: 1,
              income: 100
            },
            {
              title: 'Rail AC',
              avgTrnx: '1600',
              transactionsCount: 1,
              income: 40
            },
            {
              title: 'Rail Non AC',
              avgTrnx: '750',
              transactionsCount: 1,
              income: 20
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'Cash & EMI Collection',
      body: [
        {
          header: 'Cash & EMI Collection',
          type: 'Daily',
          body: [
            {
              title: 'Hero FinCorp',
              avgTrnx: '4000',
              transactionsCount: 1,
              income: 5.20
            },
            {
              title: 'Swiggy Agent',
              avgTrnx: '5000',
              transactionsCount: 1,
              income: 6
            },
            {
              title: 'L&T',
              avgTrnx: '2500',
              transactionsCount: 1,
              income: 3.25
            },
            {
              title: 'Bajaj FinCorp',
              avgTrnx: '3500',
              transactionsCount: 1,
              income: 4.55
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'Loans',
      body: [
        {
          header: 'All types of Loans (Monthly Trnx)',
          type: 'Monthly',
          body: [
            {
              title: 'Personal Loan',
              avgTrnx: '500000',
              transactionsCount: 1,
              income: 5000
            },
            {
              title: 'Busines Loan',
              avgTrnx: '500000',
              transactionsCount: 1,
              income: 5000
            },
            {
              title: 'Home Loan',
              avgTrnx: '1000000',
              transactionsCount: 1,
              income: 5000
            },
            {
              title: 'Home Loan Balance Transfer',
              avgTrnx: '500000',
              transactionsCount: 1,
              income: 5000
            },
            {
              title: 'Loan Against Property',
              avgTrnx: '500000',
              transactionsCount: 1,
              income: 5000
            },
            {
              title: 'Gold Loan',
              avgTrnx: '200000',
              transactionsCount: 1,
              income: 1000
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'GST & Taxation',
      body: [
        {
          header: 'Goods and Services Tax(Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'GST Registration',
              avgTrnx: '789',
              transactionsCount: 1,
              income: 99
            },
            {
              title: 'GST Filing',
              avgTrnx: '2499',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'GST Modification',
              avgTrnx: '999',
              transactionsCount: 1,
              income: 149
            },
            {
              title: 'GST E-Way',
              avgTrnx: '499',
              transactionsCount: 1,
              income: 99
            },
            {
              title: 'Cancellation of GST Registration',
              avgTrnx: '1499',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'GST-9 Annual Return Filing',
              avgTrnx: '499',
              transactionsCount: 1,
              income: 299
            }
          ]
        },
        {
          header: 'Tax Planning and Consultation(Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'Tax Planning for Salaried Individuals',
              avgTrnx: '999',
              transactionsCount: 1,
              income: 199
            },
            {
              title: 'NRI Tax Planning from Salary',
              avgTrnx: '1499',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'Advance Tax Payment & Planning',
              avgTrnx: '1499',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'Tax Planning from House Property',
              avgTrnx: '1499',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'Capital Gain Tax Planning(Long Term + Short Term)',
              avgTrnx: '2499',
              transactionsCount: 1,
              income: 399
            },
            {
              title: 'Tax Planning from Other Sources of Income',
              avgTrnx: '2499',
              transactionsCount: 1,
              income: 399
            },
            {
              title: 'ITR Tax Planning / Individual Project Report',
              avgTrnx: '1999',
              transactionsCount: 1,
              income: 299
            },
            {
              title: 'Tax Saving Plan after Retirement',
              avgTrnx: '1999',
              transactionsCount: 1,
              income: 299
            }
          ]
        }
      ]
    },
    {
      mainHeader: 'Business Services',
      body:[
        {
          header: 'Company Registration(Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'Private Limited Company',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'One Person Company Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Limited Liability Partnership',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Nidhi Company',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Section 8 Company',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Public Company Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            }
          ]
        },
        {
          header: 'Trademark & IPR (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'Trademark Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Trademark Objection',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Trademark Renewal',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Trademark Hearing',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'Trademark Opposition',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            }
          ]
        },
        {
          header: 'FSSAI/FoSCoS (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'FSSAI/FoSCoS Registration(Basic/State/Central)',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'FSSAI/FoSCos Renewal(<12L/12L-20Cr/above 20CR)',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            },
            {
              title: 'FSSAI/FoSCos Modification',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            }
          ]
        },
        {
          header: 'Import/Export License (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'iEC Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'iEC Modification',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            }
          ]
        },
        {
          header: 'PTAX (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'PTAX Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'PTECH',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            }
          ]
        },
        {
          header: 'ESIC (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'ESIC Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'ESIC Return',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            }
          ]
        },
        {
          header: 'Provident Fund (Monthly) ',
          type: 'Monthly',
          body: [
            {
              title: 'PF Registration',
              avgTrnx: '2999',
              transactionsCount: 1,
              income: 499
            },
            {
              title: 'PF Return',
              avgTrnx: '1299',
              transactionsCount: 1,
              income: 199
            }
          ]
        }
      ]
    }
  ]

  incomePerDay: number = 0;
  totalIncome: number = 0;

  curTabSelected: string = 'Neo Banking Services';
  @ViewChild('calLeftContainer') scrollableContainerRef: ElementRef;

  constructor(){
    this.valueChanged();
  }

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   const el = document.getElementById('calContainer');
  //   const containerHeight = el.offsetHeight;
  //   el.style.height = containerHeight + 500 + 'px';
  // }

  scrollIntoView(viewName){

    this.curTabSelected = viewName;

    var elementTopOffset = document.getElementById(viewName).offsetTop;
    var containerTopOffset = this.scrollableContainerRef.nativeElement.offsetTop

    this.scrollableContainerRef.nativeElement.scroll({
      top: elementTopOffset - containerTopOffset,
      left: 0,
      behavior: 'smooth'
    });

    // var myElement = document.getElementById(viewName);
    //   myElement.scrollIntoView({
    //     behavior: 'smooth', block: 'start' 
    //   });    
  }

  getIncome(incomeType: string){
    return this.data.flatMap(elem => elem.body)
                    .filter(elem => elem.type === incomeType)
                    .map(x => x.body.map(obj => (obj.income * obj.transactionsCount)).reduce((a, b) => a + b, 0))
                    .reduce((a, b) => a + b, 0);
  }

  valueChanged(){
    let dailyIncome = this.getIncome('Daily');
    let monthlyIncome = this.getIncome('Monthly');
    this.incomePerDay = Math.round(dailyIncome + (monthlyIncome / 30));
    this.totalIncome = Math.round((dailyIncome * 30) + monthlyIncome);
  }

}

interface IncomeCalculatorCategories{
  mainHeader: string,
  body: IncomeCalculatorUtilities[]
}

interface IncomeCalculatorUtilities{
  header: string,
  type: 'Daily' | 'Monthly',
  body: IncomeCalculatorBody[]
}

interface IncomeCalculatorBody{
  title: string,
  avgTrnx: string,
  transactionsCount: number,
  income: number
}