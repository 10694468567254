<section class="bg-half-170 d-table w-100 padding_100">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading me-lg-4">
                    <h1 class="heading mb-3"><span class="text-primary fw-bold">Happy Pay</span> Pre-Paid Cards - Easy to use and reloadable!</h1>
                    <p class="para-desc text-muted">Card can be instantly activated and managed via <span class="text-primary fw-bold">Happy Pay</span> Portal. Rupay debit card which is accepted online as well as offline merchants throughout India.</p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary mt-2 me-2"><i class="uil uil-envelope"></i> Get Started</a>
                        
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/images/Prepaid-Card-1.png" alt="" width="100%">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

<div class="pb-70"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Retailer and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>

            <section class="section">
            

                <!-- right content -->
    
                <div class="container mt-75 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <img src="../../../../assets/images/Prepaid-Card-3.png" class="img-fluid shadow rounded" alt="">
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title ms-lg-5">
                                <h4 class="title mb-4"><span class="text-primary fw-bold">Happy Pay Pre-Paid cards</span> comes with numerous advantages</h4>
                                
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>This RuPay card can be used anywhere in India and in return get unmatched benefits for all your online / offline purchases.</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>This RuPay card can be used anywhere in India and in return get unmatched benefits for all your online / offline purchases.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>This is debit card without a bank account, the advantage is that you don’t have to maintain minimum balance in the account.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Fully secured. You can top-up the card with the desired amount based on your spends. No need to keep big amount in the card.</li>
    
                                
    
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- right content -->
    
    
                <!-- left content -->
    
                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title me-lg-5">
                                <h4 class="title mb-4">What Makes us <span class="text-primary fw-bold">Unique?</span></h4>
                                
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Instant card issuance and activation via simple KYC method. The entire process is online and can be easily done under a minute</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Completely paperless and cashless process</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>No bank account is needed. Anyone can get a card with just online KYC</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>100% payment acceptance throughout India – online as well as offline</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Virtual card option if physical card is not needed. Activate/deactivate card at the click of a button</li>
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-5 col-md-6 order-1 order-md-2">
                            <img src="../../../../assets/images/Prepaid-Card-Unique.png" alt="" class="img-fluid">
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- left content -->
    
                
    
    
    
            </section>



            <section class=" overflow-hidden bg-light">





                <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">How to get a <span class="text-primary fw-bold">Happy Pay</span> Pre-Paid Rupay Card?</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Issuing card for self-use or for any walk-in customer is a very easy process with <span class="text-primary fw-bold">Happy Pay</span> online KYC. Just 3 easy steps and the card is live and ready for use.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
                
                <div class="row">
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card work-process border-0 rounded shadow">
                            <div class="card-body">
                                <!--<p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.</p>
                                <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a> -->
                                
                                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2"> 
                                    <li class="step h1 mb-0 fw-bold" style="color:#2f55d4; opacity: 0.2;">Step 01.</li>
                                    <li class="step-icon"><i class="uil uil-angle-double-right h1 mb-0"></i></li>
                                 </ul>
                                 <h4 class="title">Signup with Happy Pay and get your ID</h4>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card work-process border-0 rounded shadow">
                            <div class="card-body">
                                
                                <!--<p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.</p>
                                <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a> -->
                                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">
                                    <li class="step h1 mb-0 fw-bold" style="color:#2f55d4; opacity: 0.2;">Step 02.</li>
                                    <li class="step-icon"><i class="uil uil-angle-double-right h1 mb-0"></i></li>
                                </ul>
                                <h4 class="title">Generate card using online KYC</h4>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card work-process border-0 rounded shadow">
                            <div class="card-body">
                                
                                <!--<p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.</p>
                                <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a> -->
                                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">
                                    <li class="step h1 mb-0 fw-bold" style="color:#2f55d4; opacity: 0.2;">Step 03.</li>
                                    <li class="step-icon"><i class="uil uil-file-check-alt h1 mb-0"></i></li>

                                </ul>
                                <h4 class="title">Load card through Happy Pay and use it anywhere</h4>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div>



        
</section>

            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/application.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>