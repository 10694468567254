<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms of Service</li>
            </ul>
            <h2>Terms of Service</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>



<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <!-- <img src="assets/img/courses/img2.jpg" alt="image"> -->
                    <p><i>This Terms of Service was last updated on July 1, 2022.</i></p>
                    <h3> OUR WEBSITE</h3>
                    <p>Our website address is: http://happypay.com</p>
                    <blockquote class="blockquote">
                        <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You
                            further agree and acknowledge the Terms of Use and/or Terms and Conditions that may be
                            applicable to each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy
                            Pay Platform enables you to browse through Happy Pay Services provided by Happy Pay Entities
                            and in order to avail, enrol or use any such Service/s including but not limited to a
                            comprehensive suite of Banking, Financial and Insurance related products and/or services
                            such as Personal Banking/Corporate Banking/Merchant Banking and/or API Banking
                            products/services and/or such other services, from time to time, you may need to
                            additionally register for such services by providing some more information as required under
                            the Terms of Use of such products/services. Furthermore, you shall provide to any and/or all
                            Service Providers accurate and authentic information, data and/or details about you, your
                            business and/or your customers etc for the purposes of availing any of the Services made
                            available to you by Happy Pay on the Happy Pay Platform.</p>
                    </blockquote>
                    <h3> ELIGIBILITY</h3>
                    <p>
                        Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.
                    </p>


                    <h3>REGISTRATION </h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform. </p>

                    <h3>YOUR CONDUCT ON APPLICATIONS</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>LOGIN/ACCESS CREDITIALS</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>KYC(KNOW YOUR CUSTOMER)</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>FEES & CHARGES</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>THIRDPARTY TERMS& CONDITIONS</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>OFFER</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>COMMUNICATION</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>



                    <h3>INFORMATION PRESENTATIONAL/ EDITORIAL CONTROL</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                    <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                        <h3>  Spamming</h3>
                    <p> Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                    agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to each of
                    the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform enables you to
                    browse through Happy Pay Services provided by Happy Pay Entities and in order to avail, enrol or use
                    any such Service/s including but not limited to a comprehensive suite of Banking, Financial and
                    Insurance related products and/or services such as Personal Banking/Corporate Banking/Merchant
                    Banking and/or API Banking products/services and/or such other services, from time to time, you may
                    need to additionally register for such services by providing some more information as required under
                    the Terms of Use of such products/services. Furthermore, you shall provide to any and/or all Service
                    Providers accurate and authentic information, data and/or details about you, your business and/or
                    your customers etc for the purposes of availing any of the Services made available to you by Happy
                    Pay on the Happy Pay Platform.</p>



                    <h3>TERMINATION </h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                    <h3>LIMITATION OF LIABILITY</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                    <h3>INDEMNIFIACTION</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                    <h3>FORCE MAJEURE</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                    <h3>DISPUTE, GOVERNMENT LAW &JUDRISDICATION</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>
                    <h3>DISCLAIMERS</h3>
                    <p>Happy Pay and Happy Pay Entities provide Happy Pay Services via Happy Pay Platform. You further
                        agree and acknowledge the Terms of Use and/or Terms and Conditions that may be applicable to
                        each of the Happy Pay Services provided on Happy Pay Platform. Access to Happy Pay Platform
                        enables you to browse through Happy Pay Services provided by Happy Pay Entities and in order to
                        avail, enrol or use any such Service/s including but not limited to a comprehensive suite of
                        Banking, Financial and Insurance related products and/or services such as Personal
                        Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such
                        other services, from time to time, you may need to additionally register for such services by
                        providing some more information as required under the Terms of Use of such products/services.
                        Furthermore, you shall provide to any and/or all Service Providers accurate and authentic
                        information, data and/or details about you, your business and/or your customers etc for the
                        purposes of availing any of the Services made available to you by Happy Pay on the Happy Pay
                        Platform.</p>

                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/about-1">About Us</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/purchase-guide">Purchase Guide</a></li>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li class="active"><a routerLink="/terms-of-service">Terms of Service</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</div>