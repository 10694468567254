<section class="incomecal-wrapper">
    <div class="container--responsive">
        <h3 class="section-title-dashed">Income Calculator</h3>
        <p class="body-content">Change the number of transactions to calculate earnings.</p>
        <div class="tab-container">
            <a (click)="scrollIntoView(obj.mainHeader)" [ngClass]="{'cal-tab-active': curTabSelected === obj.mainHeader}" class="cal-tab" *ngFor="let obj of data">{{obj.mainHeader}}</a>
        </div>
        <div class="incomecal-container">
            <div class="cal-left" #calLeftContainer>
                <div class="cal-container" id="calContainer" *ngFor="let object of data">
                    <div class="cal-table" [id]="object.mainHeader">
                    <!-- <div class="cal-table" [id]="object.mainHeader" *ngIf="object.mainHeader === curTabSelected"> -->
                        <ng-container *ngFor="let obj of object.body">
                            <div class="cal-table-head">
                                <div class="col-service title">{{obj.header}}</div>
                                <div class="col-trans-perday title">No. of {{obj.type}} Transactions</div>
                                <div class="col-income title">Income (&#8377;)</div>
                            </div>
                            <div class="cal-table-body">
                                <ul class="cal-main">
                                    <li data-value="elem.title" *ngFor="let elem of obj.body">
                                        <div class="col-service service-label">
                                            <span class="label-text values-text">{{elem.title}}</span>
                                            <div>Avg transaction of &#8377;{{elem.avgTrnx}}</div>
                                        </div>
                                        <div class="col-trans-perday trans-input-wrap">
                                            <span class="mobile-title">No. of Daily Transactions</span>
                                            <input type="tel" class="values-text" maxlength="3" [(ngModel)]="elem.transactionsCount" (ngModelChange)="valueChanged()" onkeydown="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')">
                                        </div>
                                        <div class="col-income income-wrap">
                                            <span class="mobile-text">Daily Earning</span>
                                            <span class="values-text">&#8377;<span class="total-val">{{elem.income}}</span></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>      
                </div>
            </div>
            
            <div class="cal-right">
                <div style="height: 100%;">
                    <div sticky-element="" class="topStopped" style="position: relative; width: 432px; top: 0px; left: 0px; bottom: auto;">
                        <div class="cal-total-wrap show">
                            <div class="top-wrap">
                                <div class="cal-total-eq">
                                    <div class="eq-val">
                                        <span class="title">Income Per Day</span>
                                        <span class="value currancy">&#8377;<span id="perDayTrans">{{incomePerDay}}</span></span>
                                    </div>
                                    <div class="eq-val">
                                        <span class="value">X</span>
                                    </div>
                                    <div class="eq-val">
                                        <span class="title">Number of Days</span>
                                        <span class="value">30</span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <img src="../../../../assets/images/cal-total-img.png" alt="">
                                </div>
                            </div>
                            <div class="cal-total">
                                <div class="eq-val">
                                    <span class="title">Total Income</span>
                                    <span class="value currancy">&#8377;<span id="perMonthComisson">{{totalIncome}}</span><span class="month--text">/Month</span></span>
                                </div>
                            </div>
                            <div class="join-wrap">
                                <a href="#" class="btn green">Join HappyPay</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>