



<section class=" pb-40 overflow-hidden" id="home">
    <div class="container">
        <div class="row align-items-center pt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3">Become an  <span class="text-primary"> Happy Pay </span> Master Distributor</h1>
                    <p class="para-desc text-muted">
                     <span style="color:#0B2476">Provide 500+ Digital Services</span>  – “  <span style="color:#0B2476"> Happy Pay </span> - <span class="text-primary"> Happy Earnings </span> ”
                        </p>
             
                        <div class="content">
                     
                            <div class="bar"></div>
                            <p>  1.	Self cash-back on all Happy Pay services (Slab-2).</p>  
                            <p>    2.	All the users are eligible for referral commissions.</p>  
                                <p>    3.	All the commission will be credited to coins wallet.</p>  
                                    <p>    4.	Full KYC is mandatory to withdraw money from wallet to Bank A/C.</p>  
                                <p>    5.	Commissions on 3-levels of down-line team earnings.</p>  
                                    <p>    6.	Master Distributor Licence will be awarded from the Happy Pay (BUDDICA) Company.</p>  
                                        <p>    7.	Free worth of Rs 20,000/- Retailer IDs(10) are gifted to the Master Distributor.</p>  
                                
                    </div>

                    <div class="mt-4 pt-2">
                        <a href="#lead_form" target="" class="btn btn-primary">Join  Happy Pay</a>
                    </div>
                
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class=" position-relative">
                    <div class=" position-relative">
                        <img src="../../../../../assets/images/Happy Payqr.png" class="mx-auto d-block" alt="" width="600">
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>


<div class="pb-10"  style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:20px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Master Distributor and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>

         
                


<section class="pb-40" style="margin-top: 70px;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            
                            <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> Services </span></h4>
                           <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                          
                            <img class="text-center"src="../../../../assets/images/Recharge & Pay Bills.png" alt="image" width="210" height="200">
        
                            <h3>Bill Payments & Recharge:</h3>
                            <p style="font-size:15px">Earn more by doing DTH/ Mobile recharges, Utility bill payments, for your customers easily and quickly of more than 150 companies. Earn Huge commissions on BBPS.</p>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Banking.png" alt="image" width="210" height="200">
        
                            <h3>Banking Services:</h3>
                            <p style="font-size:15px">Convert your shop into Mini Bank through AePS, Micro-ATM & Money Transfer. Provide cash withdrawal, domestic and Indo-Nepal money transfer to earn huge commissions.</p>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Payment Services.png" alt="image" width="210" height="200">
        
                            <h3>Payment Services:</h3>
                            <p style="font-size:15px">Accept payments smartly and safely with mobile via QR code/UPI, Credit/Debit cards via mPos/ dPos and collect via link. Amount gets settled in T+1 day to Merchant Bank A/C.</p>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Travel.png" alt="image" width="210" height="200">
        
                            <h3>Travel Services:</h3>
                            <p style="font-size:15px">You can earn profitable commissions by booking Bus, Flight, Train tickets & Hotels easily and double up your monthly earnings. Earn commissionsupto 4% on all travel bookings.</p>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Loans.png" alt="image" width="210" height="200">
        
                            <h3>Loans & Insurance:</h3>
                            <p style="font-size:15px">You can earn profitable commissions by uploading the successful leads in all types of Loans(Personal loan, Home Loan etc.,) & Insurances(Life, Health & Travel Insurance etc.,)</p>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Business Solution Services.png" alt="image" width="210" height="200">
        
                            <h3>Business Solution Services:</h3>
                            <p style="font-size:15px">Provide 200+ Legal & CA services in your area just by uploading the leads of customers. Earn upto 20% of service revenue by uploading the successful leads of your customers. </p>
                        </div>
                    </div><!--end col-->


                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Govt. Services.png" alt="image" width="210" height="200">
        
                            <h3>E-Governance Services:</h3>
                            <p style="font-size:15px">You can also earn commissions by Provide Insurance, PAN card& 100+ government service to your customers. We Happy Pay offer more than 100+ govt. services in one single platform.</p>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Affiliate Partners.png" alt="image" width="210" height="200">
        
                            <h3>Affiliate Partners:</h3>
                            <p style="font-size:15px">You can also earn commissions by placing orders for your customers or yourself from the top 100+ E-commerce brands like (Amazon, Flipkart, Myntra, Tata Cliq, Meeshow etc)</p>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                          
                            <img class="text-center"src="../../../../assets/images/Cash Collection (CMS).png" alt="image" width="210" height="200">
        
                            <h3>Cashdrop Services:</h3>
                            <p style="font-size:15px">Become the cash collection center of your location and increase your monthly earning by getting a good commission on collecting cash for various categories such as Ecommerce ,etc</p>
                        </div>
                    </div>
                </div><!--end row-->
            </div>
        </section>


            

            <div id="section_footer">
                <section class="pb-40 footer_new_section" id="home section_footer" >
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happpay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width: 50%; height: 50%;">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://happypay.in/happypay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="happypay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>





