<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Refund Policy</li>
            </ul>
            <h2>Refund Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <!-- <img src="assets/img/courses/img1.jpg" alt="image"> -->
                    <p><i>This Refund Policy was last updated on July 1, 2022.</i></p>

                    <h3>Refund Policy</h3>
                    <p>Happy Pay Platform is owned/subscribed/used by Buddica Global Solutions Private Limited a company incorporated under the Companies Act, 1956 with its registered office at 49-24-25/A/4, 2nd Floor, Suri Plaza, Sankara Matam Rd, opp. Madhura Inn Hotel, Visakhapatnam, Andhra Pradesh 530016, India and also operates from its Corporate Visakhapatnam, Andhra Pradesh 530013 and India (hereinafter referred to as Happy Pay) and/or by Happy Pay Entities not limited to websites, mobile applications, devices, URLs/links, notifications, chatbot, or any other communication medium used by Happy Pay Entities to provide its services to its Users. </p>
                    <p>By accessing the Happy Pay Platform, you (or otherwise referred to as the user and may be used interchangeably as either you or the user, in this Policy Document) accept and agree to the terms, conditions and rules without limitation and or qualification. You understand that while availing Happy Pay Services, if any, that are offered by Merchants and/or Service Providers, about which Happy Pay does not have any privy, you understand that we are not a party to the contract between you and such Merchants and/or Service Providers and act only as an Intermediary (IT Act 2000). Happy Pay We reserves the right to modify the terms at any time without giving you any prior notice. Your use of the Happy Pay App / Happy Pay Platform(s), following any such modification constitutes your agreement to follow and be bound by the terms as modified. Any additional Terms and Conditions and/or Disclaimers, Privacy Policies, Refund Policies and other policies applicable in general and/or to specific areas of the Happy Pay Platform and/or to a particular / specific Service(s) are also considered as Terms.</p>
                    <p>Happy Pay does not at any point of time during any transaction between the User and the Merchant and/or Service Provider, take the ownership of any of the products/services provided by the merchant. Nor does Happy Pay at any point asserts any rights or claims over the products/services offered by the Service Provider to the user. The cancellation/refund, if any, will be governed as per the terms and conditions of the Service Provider. Happy Pay has no role in governing refund/cancellation charges. Happy Pay, will not be responsible for refund/cancellation including any charges arising therefrom.</p>

                    <p>In the event, you erroneously send a payment to a wrong party or have sent a payment for a wrong amount, Happy Pay shall have no liability in this regard and your only recourse will be to contact such third party to whom such payment was sent and seek a refund (if any). Happy Pay will not refund or reverse a payment erroneously made by you.</p>
                    <p>All sales of prepaid recharge on the Happy Pay App / Platform are final with no refund or exchange permitted. You are responsible for the mobile number or DTH Account Number for which you purchased the prepaid recharge and all charges that result from those purchases. You are also responsible for the information relating to Data Card and similar recharge services and all charges that result from those purchases. Happy Pay is not responsible for any purchase of prepaid recharge for an incorrect mobile number or DTH account number or incorrect data card information and or similar services.</p>
                    
                    <p>You shall be responsible and liable for all payments made by you using the Happy Pay Platform towards any Utility Bill (Electricity, Telephone, Mobile, Water Charges etc) and/or avail the BBPS (Bharat Bill Payment Service) offered through the Happy Pay Platform and refunds, as may be applicable, for any of these Services, shall be as per the Refund Policy/ies of the respective Utility Service Provider and/or the National Payments Corporation of India (NPCI) as may be applicable and as may be revised from time to time. You agree and acknowledge that Happy Pay and/or Happy Pay Entities do not and/or shall not have any role or scope in the processing of any of your claim for Cancellation of any/all transaction(s) initiated by you and/or your claim for any Refund.</p>
                    <p>You shall be responsible and liable for all transactions initiated by you and/or your Customer(s) on the Happy Pay Platform and/or instructions that you may provide to Happy Pay on the Happy Pay Platform while availing the Services (Services hereinafter mean and include but not limited to a comprehensive suite of Banking, Financial and Insurance related products and/or services such as Personal Banking/Corporate Banking/Merchant Banking and/or API Banking products/services and/or such other services, from time to time, more particularly defined in the User Terms of Use / Terms and Conditions published by Happy Pay on the Happy Pay Platform and updated from time to time), by you and/or by your Customer(s) and that you and your Customers shall be bound by the Cancellation and/or Refunds Policy of the respective Merchant / Service Provider who offers that Product and/or Service by virtue of Happy Pay’s backend tie-up with such Merchant / Service Providers. You agree and acknowledge that Happy Pay and/or Happy Pay Entities do not and/or shall not have any role or scope in the processing of any of your claim for Cancellation of any/all transaction(s) initiated by you and/or your claim for any Refund.</p>
                    
                    <p>You agree and authorise Happy Pay to deduct and/or recover and/or adjust any fees, charges, costs, expenses including but not limited to any claims such as Charge backs or any other claims, Merchant Discount Rate (MDR) or Merchant Service Fee (MSF) and/or such Fees and/or such amounts as may be outstanding as dues payable by you to Happy Pay from any amount(s) that may be received by Happy Pay from any of the Service Provider(s) pursuant to such Service Provider(s) processing your claim for Cancellation and/or Refund and that Happy Pay will be well within its rights and authority to transfer the net proceeds to your Wallet Account with Happy Pay, under such circumstances. Furthermore, you agree and hereby undertake that you shall not proceed against Happy Pay legally, protesting and/or contesting any such deduction or recovery and/or adjustment by Happy Pay, at any time and that you forfeit your rights thereof under any applicable law(s) in India.</p>
                    <p>Happy Pay disclaims any accountability, legal, losses/damages or else, that might arise because of the act, omission or otherwise of you, on its website/mobile application or caused by the same. You expressly admit that Happy Pay is only a payment facilitator & intermediary, and as such, stands indemnified from any accountability that might arise because of the same. You moreover acknowledge that visiting/using Happy Pay website/mobile application is an implicit reception/confirmation of this disclaimer on your part.</p>
                    
                    <p>You are requested to go through the Service Providers provided Terms and Conditions on their respective website and/or platform. Happy Pay is not responsible for your inability to access such Terms and Conditions or for any loss resulting from such Terms and Conditions or lack thereof. You agree and acknowledge that the actual contract for sale is directly between you and the Service Provider. Happy Pay does not control or prevent changes in the published details and descriptions of websites/apps operated by the Service Providers and is not responsible for any content therein.</p>
                    <p>Happy Pay has no control over the existence, quality, safety or legality of items displayed; the accuracy of the Service Provider’s content or listings; the ability of the Service Provider to sell items or provide services. Happy Pay does not at any point of time during any transaction between you and the Service Provider, take the ownership of any of the listing, bookings or services offered by the Service Provider. Nor does Happy Pay at any point asserts any rights or claims over the same offered by the Service Provider to you. The Service Provider is solely responsible for the content/listings/bookings made available by it through the Happy Pay App / Happy Pay Platform(s) and you should contact the respective Service Provider directly. For more information, we request you to contact the Service Provider in order to get further clarifications and confirmation or the same can be confirmed on their websites as well.</p>
                    <p>Notwithstanding anything contrary contained herein, the Services, the Happy Pay Platform, their respective information, pricing and data, and availability are subject at any time and from time to time to human, mechanical , typographic, or other errors, oversights, mistakes, limitations, delays, service interruptions, including, without limitation, as may be due in whole or in part to, related to or arising out of (i) computer hardware and software, telecommunication and operating systems, databases, or business processes and procedures, other problems inherent in, or which may be associated with, the use of the internet and electronic communications including, without limitation, force majeure event, government / regulatory actions, orders, notifications etc., and/or and acts and omissions of third parties etc.</p>
                    <p>Any amount transferred erroneously or for any reason by you shall not be refunded to you under any circumstances. You understand and agree that Happy Pay is not a party to the contract between you and the Service Providers. Happy Pay does not endorse any advertiser or merchant linked to its website. Furthermore, Happy Pay is under no obligation to monitor the Service Provider’s service/products used by you. The respective Service Provider alone will be responsible for all obligations under the contract including (without limitation) warranties or guarantees. Any dispute with or complaint against any Service Provider must be directly resolved by you with the Service Provider. It is clarified that Happy Pay shall not be responsible or liable for any deficiency in goods and/or services purchased by you, using the Prepaid Card made available by Happy Pay, from time to time.</p>
                    <p>Any payment made erroneously by you to any merchant establishment or any erroneous transfer to any person shall not be refunded to you by Happy Pay in any circumstances. Any web-link on the platform to a third-party site is not an endorsement of that web-link by Happy Pay. By using or browsing any such other web-link, you shall be subject to the Terms and Conditions in relation to that web-link.</p>
                    <p>In the event of any dispute, Happy Pay records shall be binding as the conclusive evidence of the transactions carried out through use of the wallet. Happy Pay will / shall not be responsible for recovering the money in case the account holder initiates fund transfer to an unintended or incorrect account.</p>
                    <p>The following Terms and Conditions shall be applicable to the provision of any fund transfer/collection and/or Customer payments acceptance facility in the form of UPI based QR and/or such other manner, from time to time, provided / facilitated by Happy Pay and availed by you: (a) You shall provide correct beneficiary details and/or such details as may be required by Happy Pay, from time to time, to Happy Pay at the time of availing the said facility. (b) You shall be solely responsible for entering wrong beneficiary details like incorrect virtual payment address or incorrect mobile number or account no or IFS Code, due to which the fund are transferred to an incorrect beneficiary. (c) You agree that the transaction / payment instruction shall become irrevocable when it is executed by Happy Pay.</p>                        
                    <p>You are responsible for the accuracy and authenticity of the instructions provided to Happy Pay and the same, if is in the form and manner prescribed by Happy Pay, shall be considered to be sufficient to operate the said facility. Happy Pay shall not be required to independently verify the instructions. Happy Pay has no liability if it does not or is unable to stop or prevent the implementation of any transaction / payment instruction order issued by you. Once a transaction / payment instruction is issued by you, the same cannot be subsequently revoked by you. All instructions, requests, directives, orders, directions, entered by you, are based upon your decisions and are the sole responsibility of you. Refunds (if any) will be credited to your Wallet Account with Happy Pay and/or your Prepaid Card Account, as may be deemed fit and proper by Happy Pay, from time to time.</p>
                    <p>Once a User chooses to avail any subscription plan/offer announced by Happy Pay and agrees to buy that plan/offer by due payment for that plan/offer to HappyPay, such payment by User shall not be refunded by HappyPay under any circumstances whatsoever. Please note that such act of buying HappyPay's plan is irreversible process under the applicable law.</p>
                    <p>Post receipt of payment from the User for the above-mentioned plan, HappyPay shall create User ID in its mobile APP ONLY post successful KYC verification of such User. If the User is unable to get successful KYC done, HappyPay shall not be able allow for availing the services mentioned for that particular subscription plan. No existing balance will be refunded to the user in case of un successful KYC verification. Thus, in order to avail HappyPay services on its mobile APP, User has to mandatorily get his successful KYC verification done.</p>
                    <p>Post User Id creation, while availing various services on HappyPay mobile APP, a transactions which have failed for any reason directly attributable to HappyPay and HappyPay has received corresponding confirmation from the payment gateway, will be automatically refunded to User's bank account within 3-21 working days from the date of transaction and a confirmation mail will be sent to User's email id registered with HappyPay corresponding confirmation from the payment gateway, will be automatically refunded to User's bank account within 3-21 working days from the date of transaction and a confirmation mail will be sent to User's email id registered with HappyPay. Please note that only the actual transaction amount will be refunded excluding payment gateway charges and all applicable taxes. However, for cases where User has received a successful completion confirmation but not received services, User is required to submit a complaint by sending an e-mail to Email ID given on this website. HappyPay shall enquire the matter after receiving the complaint from the User and based on the enquiry HappyPay may refund the payment deducting necessery professional service charges. In all cases, HappyPay's liability will be restricted to providing User a valid refund to the extent of corresponding payment received by HappyPay with respect to particular a transaction. HappyPay shall not be responsible for any other claim or consequential liability arising out of failed services on our system.</p>
                    <p>User accepts that this refund policy is subject to all the terms and conditions as stated in the Agreement of the User/business associate/retailer/distributor with HappyPay.</p>

                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/about-1">About Us</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/purchase-guide">Purchase Guide</a></li>
                            <li class="active"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</div>