



<section class="padding_200" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">
                    
                    <h1 class="heading mb-3">Insurance</h1>
                    <p class="para-desc text-muted">
                        Happy Pay has also integrated with top insurance providers in the country to enable a host of different insurance products for all its partners with the maximum commission.
                    </p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary rounded"><i class="uil uil-store"></i> CREATE ACCOUNT FOR FREE</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img style="width: 100%; height: 100%;" src="../../../../assets/images/services/loans/main.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                    </div> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container--> 
</section>

<section class="pb-40" style="margin-top: 70px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    
                    <h4 class="title mb-4"><span style="color:0B2476">  Happy Pay </span> <span class="text-primary"> Insurances </span></h4>
                   <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="top: 30px;">
                  
                    <img class="text-center"src="../../../../assets/images/Recharge & Pay Bills.png" alt="image" width="210" height="200">

                    <h3>Health Insurance</h3>
                    <p style="font-size:15px">Health insurance is a specific type of insurance policy that offers medical coverage to the insured against unforeseen medical bills in case of sudden hospitalization. Medical insurance ensures protection against diseases and injuries by directly paying the medical care provider on your behalf.A comprehensive health insurance policy covers the cost of hospitalisation including pre and post-hospitalisation expenses, medical care at home (otherwise known as Domiciliary Hospitalisation), daycare procedures, ambulance charges, amongst others.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Banking.png" alt="image" width="210" height="200">

                    <h3>Life Insurance</h3>
                    <p style="font-size:15px">Life insurance is basically an agreement between a life insurance company and a policy buyer where the insurance company agrees to pay compensation as a death benefit to the nominated beneficiaries upon the death of the insured during the term of the life insurance policy. The death benefit promised by the life insurance company is offered on the exchange of premium paid by the policy buyers. It is the safest way to safeguard your family or dependents against various financial contingencies that may occur due to an unfortunate event of your ill-timed demise. </p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Payment Services.png" alt="image" width="210" height="200">

                    <h3>Travel Insurance</h3>
                    <p style="font-size:15px">Travel insurance is basically a policy purchased for the safety as you travel a domestic or foreign destination. Travel insurance policy can sometimes play the role of a health insurance policy too, but don’t mix it up with a health cover. Since you can’t predict any mishap while travelling in a foreign land, it’s important to secure your journey by purchasing the best travel policy. Travel insurance policy can certainly be a savior in times of various emergencies while you travel overseas. Comparison of travel policy quotes is mandatory to avail the best travel insurance online.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Travel.png" alt="image" width="210" height="200">

                    <h3>Personal Insurance</h3>
                    <p style="font-size:15px">Unfortunate occurrences can come about devoid of any warning. While you are taking pleasure in every moment of your life, something unexpected might happen and blow apart everything in a fraction of second. As a result, your contentment gets disturbed and you are left with financial burden and suffering. It might lead to severe injury, disability or death. It can also end up absorbing your lifelong savings and create insufficiency in finances. In order to secure your family’s day to day life routine in your nonattendance; it’s indispensable to get a Personal Accident Insurance in India.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Loans.png" alt="image" width="210" height="200">

                    <h3>Two Wheeler Insurance</h3>
                    <p style="font-size:15px">Two wheeler insurance policy falls under the general insurance category in India. As per Motor Vehicles Act, 1988, two wheeler insurance is obligatory in the country. Bike insurance policies are designed to shield the owner of a vehicle from any unexpected or unforeseen incidents such as road accidents or any such. Online two wheeler insurance policy offers coverage to the rider or owner of a motorcycle against various unpredictable damages due to unforeseen circumstances like theft, disasters etc. Online bike insurance policy provides coverage for all types of two wheelers</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Business Solution Services.png" alt="image" width="210" height="200">

                    <h3>Home Insurance</h3>
                    <p style="font-size:15px">Home is the only place that is closest to your heart and it should be your prime obligation to secure your nest in the best way you can. Given that disasters don’t come with a prior notice and it can cost you more than you can imagine, it would be a wise decision to safeguard your house with a home insurance policy in India. A comprehensive home insurance policy can cover the cost of the contents and structure of the house. Most of the home insurance plans in India generally provide coverage against floods, earthquake, cyclone and storm and can also cover the cost of alternative accommodation.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Travel.png" alt="image" width="210" height="200">

                    <h3>Cyber Insurance</h3>
                    <p style="font-size:15px">We can't deny the importance of technology, social media and transaction over the internet nowadays, in terms of providing instant business solutions and reaching out to prospective customers. While these factors serve as benefits, they also work as a gateway to cyber attacks. Therefore, an insurance policy is very much important to deal with any sort of losses you could suffer due to the exposure to cyber attacks. This role is played by the cyber insurance policy that helps an organization or business group alleviate risk exposure by offsetting expenses regarding financial recovery after a cyber related security breach or any related event.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Loans.png" alt="image" width="210" height="200">

                    <h3>HospiCash</h3>
                    <p style="font-size:15px">The hospicash insurance plan, or hospital daily cash plan, as the name implies, is an insurance plan that pays a certain amount for each day you are hospitalized. The payment amount is set at the time of policy issuance and will not change. In the sense that it pays a fixed amount regardless of the real amount of costs, the hospital cash is a defined benefit plan. A hospital cash insurance policy will calculate coverage based on the daily allowance you choose. A hospital cash insurance policy will calculate coverage based on the daily allowance you choose. The amount of cash available on a regular basis ranges from Rs.500 to Rs.3000. Day Care Procedures are not covered under this.</p>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card single-how-it-works features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                  
                    <img class="text-center"src="../../../../assets/images/Business Solution Services.png" alt="image" width="210" height="200">

                    <h3>Fire Insurance</h3>
                    <p style="font-size:15px">Fire insurance is a type of insurance that provides coverage if your property gets damaged or destroyed due to fire. Basically fire insurance policy in India is a contract under which the insurance company pays money as compensation if your properties are destroyed or damaged in fire. In other words, this insurance is designed to cover the insured for loss or damage to buildings and personal property by fire.Fire insurance policies not only provide coverage for the expenses incurred for the reconstruction, replacement and repair of the insured property, but also the nearby structures. Fire insurance policy in India is governed by All India Fire tariff.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</section>


<div id="section_footer">
    <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                <div class="container">
                    <div class="row mt-5 align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading">
                                <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                <div class="mt-4">
                                    <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="text-md-end text-center ms-lg-4">
                                <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width:50%;height:50%;">
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!--end container-->
            </section>
    
    
    
            
        <!-- common on all pages -->
    
            </div>