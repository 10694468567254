<section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;background-color: #fff;">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading">
                    <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                    <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                    <div class="mt-4">
                        <a href="https://play.google.com/store/apps/details?id=in.Happy Pay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="text-md-end text-center ms-lg-4">
                    <img style="width:50%;height:50%;"src="../../../../../assets/images/mode.png" class="img-fluid" alt="">
                </div>
            </div>
        </div><!--end row-->
    </div><!--end container-->
</section>