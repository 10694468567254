<section class="d-table w-100 pb-40 h-auto" id="home">
    <div class="container">
        <div class="row align-items-center pt-4">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading mt-4">
                    <h1 class="heading mb-3">Powerful <span class="text-primary fw-bold">REST APIs</span> for Financial Services!</h1>
                    <p class="para-desc text-muted">Happy Pay provides numerous APIs like Money transfer API, Recharge API, Pancard API, AEPS API &amp; many more to enhance the various business transactions. It aims to enable smooth and hassle-free integration with any B2B or B2C portal.</p>
                    <div class="mt-4 pt-2">
                        <a href="#lead_form" class="btn btn-primary m-1">Become API Partner</a>
                        
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/images/API-1.png" alt="" width="100%">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

<div class="pb-10"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become an API Partner and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>



            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            
                            <h4 class="title mb-4">Explore awesome features</h4>
                           <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify " data-icon="uil:airplay" style="color: #2f55d4;"></span>
                                </i>
                            </div>
                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Powerful REST APIs</h5>
                                <p class="text-muted">Integrate banking and utility services into your applications with our bundle of flexible APIs used by many big organizations.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify" data-icon="uil:clipboard-alt" style="color: #2f55d4;"></span>
                                </i>
                            </div>

                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Elastic Platform</h5>
                                <p class="text-muted">Scalable cloud platform that auto-scales to manage every kind of load so that you get to enjoy uninterrupted transactions.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify" data-icon="uil:credit-card-search" style="color: #2f55d4;"></span>
                                </i>
                            </div>

                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Clear Documentation</h5>
                                <p class="text-muted">Get started quickly with clear docs, code samples and SDKs to guide you throughout the implementation.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify" data-icon="uil:ruler-combined" style="color: #2f55d4;"></span>
                                </i>
                            </div>

                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Secure API</h5>
                                <p class="text-muted">Several verification levels to maximize the security of requests between our API and your infrastructure to prevent frauds.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify" data-icon="uil:exposure-alt" style="color: #2f55d4;"></span>
                                </i>
                            </div>

                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Dedicated Support</h5>
                                <p class="text-muted">Meet your strict go-live schedules with our fast, helpful implementation support via phone, chat or email.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="d-block rounded h3 mb-0">
                                    <span class="iconify" data-icon="uil:clock" style="color: #2f55d4;"></span>
                                </i>
                            </div>

                            <div class="card-body p-0 content">
                                <h5 class="mt-4 text-primary fw-bold">Comprehensive Dashboard</h5>
                                <p class="text-muted">A complete dashboard, your one stop solution to manage all the API transactions at one place.</p>

                                
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div>



            <div class="container mt-100 mt-60">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-6">
                        <img src="../../../../assets/images/APIs-Available-for-Integration-Image.png" class="img-fluid" alt="">
                    </div><!--end col-->

                    <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="section-title ms-lg-5" style="text-align:left;">
                            <h4 class="title mb-4"><span class="text-primary fw-bold">APIs</span> Available for Integration</h4>
                            
                            <h5><i class="text-primary me-1"><span class="iconify" data-icon="uil:arrow-circle-right" style="color: #2f55d4;"></span></i>Banking Services </h5>
                            <p class="text-muted para-desc ms-4 ps-2">Different financial services like Money Transfer, AEPS, Cash Withdrawal, Cash Deposit etc., all APIs under one roof</p>
                            <h5><i class="text-primary me-1"><span class="iconify" data-icon="uil:arrow-circle-right" style="color: #2f55d4;"></span></i>Utility Services</h5>
                            <p class="text-muted para-desc ms-4 ps-2">Number of utility services like Bill Payment, Mobile or DTH recharge, PAN Card issuance and a lot more.</p>
                            <h5><i class="text-primary me-1"><span class="iconify" data-icon="uil:arrow-circle-right" style="color: #2f55d4;"></span></i>Verification APIs</h5>
                            <p class="text-muted para-desc ms-4 ps-2">Now secured onboarding of merchants has been made easy by different verification APIs like Bank Account or PAN Card verification.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div>

            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/mode.png" class="img-fluid" alt="" style="width: 50%; height: 50%;">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                     
                        </div>