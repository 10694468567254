<section class="bg-half-170 d-table w-100 padding_100">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading me-lg-4">
                    <h1 class="heading mb-3">Payment Solutions<br><span class="text-primary" style="font-size: 26px;">Collecting Payments was Never this Easy!</span> </h1>
                    <p class="para-desc text-muted">Happy Pay brings to you not one or two but 4 different ways of accepting payments form your customers at the click of a button.</p>
                    <div class="mt-4">
                        <a href="#lead_form" class="btn btn-primary mt-2 me-2"><i class="uil uil-envelope"></i> Get Started</a>
                        
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/images/Payment-Solution-top-image.png" alt="" width="100%">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

<div class="pb-70"style="    background: linear-gradient(
    -45deg, rgba(21,64,214,1), rgba(42,188,238,1),rgba(21,64,214,1),rgba(42,188,238,1) ); padding:50px; animation: gradient 15s ease infinite; @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }">
    
               <h3 style="
               text-align: center;color:#fff;">Become a Retailer and Earn upto Rs.1 Lakh per month.</h3>
                <div class="" style="
                text-align: center;color:#fff;"> 
                       <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#myModal1">
                            <div class="btn btn-primary">Sign Up</div>
                        </a>
                    </div>
            </div>


            <section class="section bg-light">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                
                                <h4 class="title mt-3 mb-4"><span class="text-primary fw-bold">Happy Pay</span> Payment Collection Solutions</h4>
                                <p class="text-muted para-desc mx-auto mb-0">One among the best solutions available in the market for collecting payment against all your sales on the go with different modes of payments.</p>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
    
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mt-4 pt-2">
                            <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                                <div class="icons text-primary text-center mx-auto">
                                    <i class="uil uil-envelope-lock d-block rounded h3 mb-0"></i>
                                </div>
    
                                <div class="card-body p-0 mt-4">
                                    <h5>Lowest Charges</h5>
                                    <p class="text-muted mb-0">Now collect payment from your customers free. Generate your own QR code and get all payments at 0 cost.</p>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-4 col-md-6 mt-4 pt-2">
                            <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                                <div class="icons text-primary text-center mx-auto">
                                    <i class="uil uil-edit d-block rounded h3 mb-0"></i>
                                </div>
    
                                <div class="card-body p-0 mt-4">
                                    <h5>Instant Activation</h5>
                                    <p class="text-muted mb-0">All payment modes are activated as soon as you go live, instant UPI code generation. The fastest way to collect payment.</p>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-4 col-md-6 mt-4 pt-2">
                            <div class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                                <div class="icons text-primary text-center mx-auto">
                                    <i class="uil uil-circle-layer d-block rounded h3 mb-0"></i>
                                </div>
    
                                <div class="card-body p-0 mt-4">
                                    <h5>App and Web</h5>
                                    <p class="text-muted mb-0">Collect payment on the go, all modes of payment collection are available on both - web as well as android application.</p>
                                    
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
    
            </section>


            <section class="padding_head_below">
            

                <!-- right content -->
    
                <div class="container mt-75 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <img src="../../../../assets/images/Aadhaar-Pay.png" alt="" width="80%">
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title ms-lg-5">
                                <h4 class="title mb-4">Aadhaar Pay</h4>
                                <p class="text-muted"><span class="text-primary fw-bold">Aadhaar Pay</span> is a payment system which enables Happy Pay retailers to collect payments from a customer using his Aadhaar number and biometric authentication. As soon as the finger print is validated, the account holder bank account is debited, and retailer gets the amount in his wallet instantly.</p>
                                <p class="text-muted">Features which makes it one of the best payment solutions.</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>24/7 operability. No dependence on banks.</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Safest payment mode as its based on biometric authentication</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Instant top-up in wallet. No delay in complete transaction cycle</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Lower transaction charges compared to debit or credit card</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Cashless payment solution, no need to carry any physical card.</li>
    
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- right content -->
    
    
                <!-- left content -->
    
                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title me-lg-5">
                                <h4 class="title mb-4">MPOS</h4>
                                <p class="text-muted"><span class="text-primary fw-bold">Happy Pay MPOS</span> machine is a solution for merchants who want to receive card payment anywhere, anytime, and who want to extend additional services to their customers. It is also known as Mini Atm or Micro Atm a terminal connected through Bluetooth to a mobile device and can read magnetic stripes, contact less cards, and chip &amp; pin cards.</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Accept card payments (VISA/Master/Rupay Card).</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Compatible with all Android mobile devices.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Accept credit/debit card payments anytime, anywhere with a Smartphone.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Retailers can start accepting mobile payments instantly. Fast activation once successful KYC is done.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Card Data is encrypted by M-POS reader before it is transmitted to the mobile app and the data are transmitted in secure channel.</li>
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-5 col-md-6 order-1 order-md-2">
                            <img src="../../../../assets/images/MPOS.png" alt="" class="img-fluid">
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- left content -->
    
                <!-- right content -->
    
                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <img src="../../../../assets/images/Payment-Link.png" class="img-fluid shadow rounded" alt="">
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title ms-lg-5">
                                <h4 class="title mb-4">Payment Link</h4>
                                <p class="text-muted"><span class="text-primary fw-bold">Payment Link</span> is a request for payment through a securely generated web link/url from Happy Pay portal or application which the recipient customers can click to make online payments instantly using different modes of payments. This link can be shared across various communication channels like Email, SMS, Whatsapp, social media, etc.</p>
                                
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Different modes of payment options to choose from like – debit card, credit card, internet banking, UPI, wallets etc.</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Instant and quick transactions and real-time settlement in wallet. User can check status of any link whenever he needs.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Different charges for different payment modes. User can set the charge from his Back-office</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Easy reconciliation and accounting as all the reports are available at the click of a button.</li>
    
                                
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- right content -->
    
                <!-- left content -->
    
                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title me-lg-5">
                                <h4 class="title mb-4">UPI &amp; QR</h4>
                                <p class="text-muted"><span class="text-primary fw-bold">UPI - Unified Payments Interface</span> is an instant real-time payment system developed by National Payments Corporation of India facilitating inter-bank transactions. The interface is regulated by the Reserve Bank of India and works by instantly transferring funds between two bank accounts on a mobile platform. </p>
                                <p class="text-muted">The best part is that there is no need to enter bank account details such as account number, IFSC code, while making the transfer. Only details required for funds transfer is the VPA ID of customer or any UPI app.</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Zero charge on payment collection through UPI or QR code</li>
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Instantly generate dynamic QR for fixed amount and share it with the customer through Mail, Whatsapp, social media, etc.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Customer can scan the QR code from any UPI app like BHIM, Phonepe, Google Pay, Amazon Pay or any UPI enabled bank.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Fastest way to collect payment form the customer. Collect payment within a second from your customer.</li>
    
                                    <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Static QR code, which the user can print and paste in his shop, which can be scanned though any UPI app and instant payment can be made.</li>
                                </ul>
                                <a href="javascript:void(0)" class="h6 btn btn-primary mt-2 me-2">Join Now <i class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-5 col-md-6 order-1 order-md-2">
                            <img src="../../../../assets/images/QR-Code.png" alt="" class="img-fluid">
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
    
                <!-- left content -->
    
    
    
            </section>

            <section class="">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Why Choose Us?</h4>
                                <p class="text-muted para-desc mb-0 mx-auto"><span class="text-primary fw-bold">Happy Pay</span> offers its retailers numerous advantages over its competitors. Join today and fulfill your dreams of becoming a business owner.</p>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
    
                    <div class="row">
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-chart-line"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Hign Performance</h5>
                                    <p class="para text-muted mb-0">High speed servers for optimum performance.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-chart-line"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-crosshairs"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Fully Secured</h5>
                                    <p class="para text-muted mb-0">Extremely secured servers to ensure full safety.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-crosshairs"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-airplay"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Trusted Portal</h5>
                                    <p class="para text-muted mb-0">10 years old portal with over 1 lac retailers.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-airplay"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-rocket"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Latest Technology</h5>
                                    <p class="para text-muted mb-0">Built on advanced technology for fast transactions.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-rocket"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-clock"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Support</h5>
                                    <p class="para text-muted mb-0">Dedicated team to solve any issue in no time.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-clock"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-users-alt"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Digital Banker</h5>
                                    <p class="para text-muted mb-0">Multiple banking services under one roof.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-users-alt"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-file-alt"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>One Wallet</h5>
                                    <p class="para text-muted mb-0">Multiple services under one common wallet.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-file-alt"></i>
                                </span>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-4 mt-4 pt-2">
                            <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                                <span class="h1 icon2 text-primary">
                                    <i class="uil uil-search"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5>Real Time Income</h5>
                                    <p class="para text-muted mb-0">Get commission as soon as the transaction is done.</p>
                                </div>
                                <span class="big-icon text-center">
                                    <i class="uil uil-search"></i>
                                </span>
                            </div>
                        </div><!--end col-->
    
                        
                    </div><!--end row-->
                </div><!--end container-->
    
    
     
                
            </section>


            <div id="section_footer">
                <section class=" d-table w-100 footer_new_section" id="home section_footer" style="padding: 55px 0px;">
                            <div class="container">
                                <div class="row mt-5 align-items-center">
                                    <div class="col-lg-6 col-md-7">
                                        <div class="title-heading">
                                            <h4 class="heading mb-3">Download <span class="text-primary fw-bold">Happy Pay</span> application from Google play-store</h4>
                                            <p class="para-desc text-muted">Signup and create your account. In 48 hours you can start your own business and increase your income</p>
                                            <div class="mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=in.happypay.app" target="_blank" class="btn btn-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                
                                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                        <div class="text-md-end text-center ms-lg-4">
                                            <img src="../../../../assets/images/application.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                
                
                
                        
                    <!-- common on all pages -->
                
                        <section class="section bg-cta" id="lead_form" style="background: url('../../../../assets/images/Common-Image-Last-on-the-Page.jpg') center center;opacity: 0.5;">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                     <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 rounded">
                                            <div class="card-body">
                                                <form class="" >
                                                    <h5>Join Now!</h5>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Name <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                                    <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                        
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phone <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                                    <input type="tel" pattern="[0-9]{10}" id="number" class="form-control ps-5" name="phone" placeholder="Phone (Enter 10 Digits)" required="">
                                                                </div>
                                                            </div>
                                                        </div><!--end col-->
                
                
                                                        <div class="col-lg-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Join as <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    <select type="drop-down" id="option" class="form-control ps-5" name="position" placeholder="Join as" required="">
                                                                            <option value="Retailer">Retailer</option>
                                                                            <option value="Distributor">Distributor</option>
                                                                            <option value="Super_Distributor">Super Distributor</option>
                                                                            <option value="Whitelable_Partner">Whitelable Partner</option>
                                                                            <option value="API">API</option>
                                                                          </select>
                                                                </div>  
                                                            </div>
                                                        </div><!--end col-->
                                                    <!--  <div class="col-lg-12">
                                                            <img src="captcha.php" width="220" height="60" />
                                                            <input type="text" class="form-control ps-5" size="6" maxlength="5" name="captcha_1" value="" placeholder="Enter 5 digits here">
                                                       </div>
                                                    -->
                                                    <input type="hidden" value="https://Happy Pay.in/Happy Pay-retailer.php" name="url">
                                                                                            <input type="hidden" value="" name="campaign">
                                                         <input type="hidden" value="Happy Pay-retailer" name="page_name">
                                                        <div class="col-lg-12 mt-2 mb-0">
                                                            <div class="d-grid">
                                                                <button type="submit" class="btn btn-primary">Register Now</button>
                                                            </div>
                                                        </div><!--end col-->
                                                    </div>
                                                </form>  
                                            </div>
                                        </div>
                                    </div><!--end col-->
                                    <div class="col-lg-8 col-md-7 col-12">
                                        <div class="section-title">
                                            <h4 class="title title-dark text-white mb-4">Happy Pay</h4>
                                            <p class="para-desc para-dark mb-0 text-light">Become a part of the fastest growing network of Independent Business Owners and become financially independent.</p>
                                        </div>
                                        <div class="row" id="counter">
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-graduation-cap title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="500">500</span>+</h5>
                                                    <h6 class="counter-head title-dark text-light">Districts Covered</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-book-open title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="1">1</span>Lac+</h5>
                                                    <h6 class="counter-head title-dark text-light">Retail Touch Points</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-user title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="10">10</span>Lacs+</h5>
                                                    <h6 class="counter-head title-dark text-light">Monthly Unique Customers</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                        
                                            <div class="col-md-3 col-6 mt-4 pt-2">
                                                <div class="counter-box">
                                                    <i class="uil uil-english-to-chinese title-dark text-light h2"></i>
                                                    <h5 class="mb-0 text-white title-dark mt-2"><span class="counter-value" data-target="15">15</span>Cr+</h5>
                                                    <h6 class="counter-head title-dark text-light">Daily Transaction Volume</h6>
                                                </div><!--end counter box-->
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end col-->
                
                                   
                                </div><!--end row-->
                            </div><!--end container-->
                        </section>
                        </div>