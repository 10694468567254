import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-on-partner',
  templateUrl: './add-on-partner.component.html',
  styleUrls: ['./add-on-partner.component.scss']
})
export class AddOnPartnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
